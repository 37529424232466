
        
    const runtime = require("../../node_modules/simple-nunjucks-loader/lib/cjs/runtime.js");
    
    const __nunjucks_module_dependencies__ = {};
    
    __nunjucks_module_dependencies__.templates = {};
    __nunjucks_module_dependencies__.globals = {};
    __nunjucks_module_dependencies__.extensions = {};
    __nunjucks_module_dependencies__.filters = {};
    __nunjucks_module_dependencies__.assets = {};
    
    
    
    
    
    
        
            __nunjucks_module_dependencies__.templates["index.nunjucks"] = (function() {
                function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<!DOCTYPE html>\r\n<html lang=\"en\">\r\n   <head>\r\n      <meta charset=\"UTF-8\">\r\n      <meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\">\r\n      <title>Club Assist Index Page</title>\r\n   </head>\r\n\r\n   <body style=\"padding:20px\">\r\n      <h1>Club Assist Pages</h1>\r\n      <h2>Brand: <span style=\"text-transform:uppercase\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "ENV_BRAND"), env.opts.autoescape);
output += "</span></h2>\r\n      <ul style=\"margin-top:10px;font-size:18px;padding-left:10px; \">\r\n         ";
frame = frame.push();
var t_3 = runtime.contextOrFrameLookup(context, frame, "htmlPageNames");
if(t_3) {t_3 = runtime.fromIterator(t_3);
var t_2 = t_3.length;
for(var t_1=0; t_1 < t_3.length; t_1++) {
var t_4 = t_3[t_1];
frame.set("page", t_4);
frame.set("loop.index", t_1 + 1);
frame.set("loop.index0", t_1);
frame.set("loop.revindex", t_2 - t_1);
frame.set("loop.revindex0", t_2 - t_1 - 1);
frame.set("loop.first", t_1 === 0);
frame.set("loop.last", t_1 === t_2 - 1);
frame.set("loop.length", t_2);
output += "\r\n            <li>\r\n               - <a href=\"";
output += runtime.suppressValue("/fe/" + runtime.contextOrFrameLookup(context, frame, "ENV_BRAND") + "/pages/" + t_4 + ".html", env.opts.autoescape);
if(t_4 == "appointment-success" || t_4 == "edit-booking") {
output += "?booking=12345678";
;
}
output += "\">";
output += runtime.suppressValue(t_4, env.opts.autoescape);
output += ".html</a>\r\n            </li>\r\n         ";
;
}
}
frame = frame.pop();
output += "\r\n      </ul>\r\n   </body>\r\n</html>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

            })();
        

        function nunjucksTemplate(ctx = {}) {
            const templateContext = {
                __nunjucks_loader_assets__: __nunjucks_module_dependencies__.assets,
                ...ctx
            };

            var nunjucks = (runtime && runtime.default || runtime)(
                {"dev":false,"jinjaCompat":false,"isAsyncTemplate":false},
                __nunjucks_module_dependencies__
            );

            if (nunjucks.isAsync()) {
                return nunjucks.renderAsync(
                    "index.nunjucks",
                    templateContext
                );
            }
        
            return nunjucks.render(
                "index.nunjucks",
                templateContext
            );
        };

        nunjucksTemplate.__nunjucks_precompiled_template__ = __nunjucks_module_dependencies__.templates["index.nunjucks"];
        nunjucksTemplate.__nunjucks_module_dependencies__ = __nunjucks_module_dependencies__;

        exports = module.exports = nunjucksTemplate;
    