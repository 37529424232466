"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
var _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.BatteryReset = exports.BatteryCardSelectedObj = exports.ClearMembership = exports.BatteryContinueSuccess = exports.BatteryBookingType = exports.BatteryMembership = exports.BatteryFetchStart = exports.selectIsMember = exports.selectBatteryCardObj = exports.selectVehicleVariationInFitment = exports.selectMembershipId = exports.selectBatteryMemberPrice = exports.selectBatteryBasePrice = exports.selectBookingTypeId = exports.selectMembershipEligibility = exports.selectMembershipStatus = exports.selectVehicleRegRequiredMessageBox = exports.selectBatteryStart = exports.batterySlice = void 0;
//React + Pkgs
var toolkit_1 = require("@reduxjs/toolkit");
var constants_1 = require("@/utils/constants");
var $batteryManagedText = constants_1.WINDOW_MESSAGES === null || constants_1.WINDOW_MESSAGES === void 0 ? void 0 : constants_1.WINDOW_MESSAGES.Battery;
var types_1 = require("@/brand-settings/brands/racwa/types");
var initialState = {
    batteries: [],
    vehicleRegRequiredMessageBox: null,
    vehicleVariationInFitment: null,
    isMembershipValid: "",
    isMembershipEligible: "",
    memberId: "",
    isBatteryAvailable: "",
    bookingTypeId: (_b = $batteryManagedText === null || $batteryManagedText === void 0 ? void 0 : $batteryManagedText.bookingType) === null || _b === void 0 ? void 0 : _b.find(function (item) { return item.id === types_1.EBookingTypeId.deliveryInstall; })["id"],
    batteryObj: {
        stockNumber: "",
        basePrice: 0,
        memberPrice: 0,
        priceVariants: null,
        description: "",
        imageID: "",
        mostPopular: false,
        standardFitment: false,
        imageSrc: "",
        imageAlt: "",
        warranty: "",
        description2: "",
        length: "",
        width: "",
        aH20hr: "",
        rcMins: "",
        ccAmps: "",
        batteryTechnology: "",
        batteryType: "",
        postType: "",
        features: "",
        ledges: "",
        assemblyCode: "",
        price: 0,
    },
    batteryPartNumber: "",
    batteryPartNumbers: [],
    isMember: "",
};
exports.batterySlice = (0, toolkit_1.createSlice)({
    name: "battery",
    initialState: initialState,
    reducers: {
        BatteryFetchStart: function (state, action) {
            var _a = action.payload, data = _a.data, vehicleRegRequiredMessageBox = _a.vehicleRegRequiredMessageBox, vehicleVariationInFitment = _a.vehicleVariationInFitment;
            return __assign(__assign({}, state), { batteries: data ? __spreadArray([], data, true) : [], vehicleRegRequiredMessageBox: vehicleRegRequiredMessageBox ? vehicleRegRequiredMessageBox : null, vehicleVariationInFitment: vehicleVariationInFitment ? vehicleVariationInFitment : null });
        },
        BatteryMembership: function (state, action) {
            var _a = action.payload ? action.payload : "", isMembershipValid = _a.isMembershipValid, isMembershipEligible = _a.isMembershipEligible, memberId = _a.memberId, isMember = _a.isMember; //if member number payload not exist after dispatch then empty below fields
            return __assign(__assign({}, state), { isMembershipValid: isMembershipValid === undefined ? "" : isMembershipValid, isMembershipEligible: isMembershipEligible === undefined ? "" : isMembershipEligible, memberId: memberId === undefined ? "" : memberId, isMember: isMember === undefined ? "" : isMember });
        },
        BatteryBookingType: function (state, action) {
            var payload = action.payload;
            var bookingTypeId = payload.bookingTypeId;
            // BOOKING STEP PRICE SUMMARY
            return __assign(__assign({}, state), { bookingTypeId: bookingTypeId ? bookingTypeId : state.bookingTypeId });
        },
        BatteryCardSelectedObj: function (state, action) {
            var data = action.payload.data;
            var bookingTypeId = action.payload.bookingTypeId ? action.payload.bookingTypeId : "";
            return __assign(__assign({}, state), { bookingTypeId: bookingTypeId ? bookingTypeId : state.bookingTypeId, batteryObj: data ? data : {} });
        },
        BatteryContinueSuccess: function (state, action) {
            var _a = action.payload, data = _a.data, isBatteryAvailable = _a.isBatteryAvailable;
            return __assign(__assign({}, state), { isBatteryAvailable: isBatteryAvailable, batteryPartNumber: data.stockNumber, batteryObj: data ? data : {} });
        },
        ClearMembership: function (state, action) {
            return __assign(__assign({}, state), { isMembershipValid: "", isMembershipEligible: "", memberId: "" });
        },
        BatteryReset: function (state) {
            return __assign({}, initialState);
        },
    },
});
var selectBatteryStart = function (state) { return state.battery.batteries; };
exports.selectBatteryStart = selectBatteryStart;
var selectVehicleRegRequiredMessageBox = function (state) { return state.battery.vehicleRegRequiredMessageBox; };
exports.selectVehicleRegRequiredMessageBox = selectVehicleRegRequiredMessageBox;
var selectMembershipStatus = function (state) { return state.battery.isMembershipValid; };
exports.selectMembershipStatus = selectMembershipStatus;
var selectMembershipEligibility = function (state) { return state.battery.isMembershipEligible; };
exports.selectMembershipEligibility = selectMembershipEligibility;
var selectBookingTypeId = function (state) { return state.battery.bookingTypeId; };
exports.selectBookingTypeId = selectBookingTypeId;
var selectBatteryBasePrice = function (state) { return state.battery.basePrice; };
exports.selectBatteryBasePrice = selectBatteryBasePrice;
var selectBatteryMemberPrice = function (state) { return state.battery.memberPrice; };
exports.selectBatteryMemberPrice = selectBatteryMemberPrice;
var selectMembershipId = function (state) { return state.battery.memberId; };
exports.selectMembershipId = selectMembershipId;
var selectVehicleVariationInFitment = function (state) { return state.battery.vehicleVariationInFitment; };
exports.selectVehicleVariationInFitment = selectVehicleVariationInFitment;
var selectBatteryCardObj = function (state) { return state.battery.batteryObj; };
exports.selectBatteryCardObj = selectBatteryCardObj;
var selectIsMember = function (state) { return state.battery.isMember; };
exports.selectIsMember = selectIsMember;
//Actions
exports.BatteryFetchStart = (_a = exports.batterySlice.actions, _a.BatteryFetchStart), exports.BatteryMembership = _a.BatteryMembership, exports.BatteryBookingType = _a.BatteryBookingType, exports.BatteryContinueSuccess = _a.BatteryContinueSuccess, exports.ClearMembership = _a.ClearMembership, exports.BatteryCardSelectedObj = _a.BatteryCardSelectedObj, exports.BatteryReset = _a.BatteryReset;
exports.default = exports.batterySlice.reducer;
