"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var _a, _b, _c;
Object.defineProperty(exports, "__esModule", { value: true });
exports.StepResetDependencies = exports.StepDependencies = void 0;
var steps_1 = __importStar(require("../nrma/steps"));
var steps_2 = __importStar(require("../racv/steps"));
var steps_3 = __importStar(require("../ract/steps"));
var steps_4 = __importStar(require("../racq/steps"));
var steps_5 = __importStar(require("../racwa/steps"));
var steps_6 = __importStar(require("../aant/steps"));
var steps_7 = __importStar(require("../raa/steps"));
var brands = {
    nrma: { module: steps_1.default, stepDeps: steps_1.stepDependencies, stepResetDeps: steps_1.stepResetDependencies },
    racv: { module: steps_2.default, stepDeps: steps_2.stepDependencies, stepResetDeps: steps_2.stepResetDependencies },
    ract: { module: steps_3.default, stepDeps: steps_3.stepDependencies, stepResetDeps: steps_3.stepResetDependencies },
    racq: { module: steps_4.default, stepDeps: steps_4.stepDependencies, stepResetDeps: steps_4.stepResetDependencies },
    racwa: { module: steps_5.default, stepDeps: steps_5.stepDependencies, stepResetDeps: steps_5.stepResetDependencies },
    aant: { module: steps_6.default, stepDeps: steps_6.stepDependencies, stepResetDeps: steps_6.stepResetDependencies },
    raa: { module: steps_7.default, stepDeps: steps_7.stepDependencies, stepResetDeps: steps_7.stepResetDependencies },
};
var brandKey = process.env.ENV_BRAND;
var BrandBookingSteps = ((_a = brands[brandKey]) === null || _a === void 0 ? void 0 : _a.module) || null;
var StepDependencies = ((_b = brands[brandKey]) === null || _b === void 0 ? void 0 : _b.stepDeps) || null;
exports.StepDependencies = StepDependencies;
var StepResetDependencies = ((_c = brands[brandKey]) === null || _c === void 0 ? void 0 : _c.stepResetDeps) || null;
exports.StepResetDependencies = StepResetDependencies;
exports.default = BrandBookingSteps;
