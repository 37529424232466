"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ManualAddressModel = exports.LocationModel = exports.VehicleModel = exports.PurchaserInformationModel = exports.AttendeeInformationModel = exports.BookingSlotModel = exports.BatteryModel = void 0;
var FUNCTIONS = __importStar(require("../utils"));
//TODO: CONVERT ALL THESE TO TYPES / INTERFACES
//THIS FILE CAN BECOME REDUDANT AFTER SWTICHING OVER
var BatteryModel = /** @class */ (function () {
    function BatteryModel(stockNumber, memberPrice, basePrice, description, warranty, aH20hr, rcMins, ccAmps, batteryTechnology, batteryType, postType, requirements, imageID) {
        this.stockNumber = stockNumber;
        this.memberPrice = memberPrice;
        this.basePrice = basePrice;
        this.description = description;
        this.warranty = warranty;
        this.aH20hr = aH20hr;
        this.rcMins = rcMins;
        this.ccAmps = ccAmps;
        this.batteryTechnology = batteryTechnology;
        this.batteryType = batteryType;
        this.postType = postType;
        this.requirements = requirements;
        this.imageID = imageID;
    }
    return BatteryModel;
}());
exports.BatteryModel = BatteryModel;
var BookingSlotModel = /** @class */ (function () {
    function BookingSlotModel(bookingSlotID, bookingStartTime, bookingEndTime, maximumAvailableBookings) {
        this.bookingSlotID = bookingSlotID;
        this.bookingStartTime = bookingStartTime;
        this.bookingEndTime = bookingEndTime;
        this.maximumAvailableBookings = maximumAvailableBookings;
    }
    return BookingSlotModel;
}());
exports.BookingSlotModel = BookingSlotModel;
var AttendeeInformationModel = /** @class */ (function () {
    function AttendeeInformationModel(firstName, lastName, phoneNumber, sameAsPurchaser) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.phoneNumber = phoneNumber;
        this.sameAsPurchaser = sameAsPurchaser;
    }
    return AttendeeInformationModel;
}());
exports.AttendeeInformationModel = AttendeeInformationModel;
var PurchaserInformationModel = /** @class */ (function () {
    function PurchaserInformationModel(email, firstName, lastName, memberId, phoneNumber) {
        this.email = email;
        this.firstName = firstName;
        this.lastName = lastName;
        this.memberId = memberId;
        this.phoneNumber = phoneNumber;
    }
    return PurchaserInformationModel;
}());
exports.PurchaserInformationModel = PurchaserInformationModel;
var VehicleModel = /** @class */ (function () {
    function VehicleModel(colour, make, model, registrationNumber, variant, vehicleId, yearGrouping) {
        this.colour = colour;
        this.make = make;
        this.model = model;
        this.registrationNumber = registrationNumber;
        this.variant = variant;
        this.vehicleId = vehicleId ? vehicleId : "";
        this.yearGrouping = yearGrouping;
    }
    return VehicleModel;
}());
exports.VehicleModel = VehicleModel;
var LocationModel = /** @class */ (function () {
    function LocationModel(fullAddress, streetAddress, suburb, state, postcode, unitType, unit, streetNumber, street1, street2, additionalNotes, externalLocationID) {
        this.fullAddress = fullAddress;
        this.streetAddress = streetAddress;
        this.suburb = suburb;
        this.state = state;
        this.postcode = postcode;
        this.unitType = unitType;
        this.unit = unit;
        this.streetNumber = streetNumber;
        this.street1 = street1;
        this.street2 = street2;
        this.additionalNotes = additionalNotes;
        this.externalLocationID = externalLocationID;
    }
    return LocationModel;
}());
exports.LocationModel = LocationModel;
var ManualAddressModel = /** @class */ (function () {
    function ManualAddressModel(streetAddress, state, suburb, postcode) {
        this.streetAddress = streetAddress;
        this.state = state;
        this.suburb = suburb;
        this.postcode = postcode;
    }
    return ManualAddressModel;
}());
exports.ManualAddressModel = ManualAddressModel;
var BookingRequestModel = /** @class */ (function () {
    function BookingRequestModel(bookingStatus, bookingDate, bookingSlot, bookingTypeId, batteryPartNumber, battery, purchaserInformation, attendeeInformation, location, vehicleInformation, promotionalCode) {
        this.bookingStatus = bookingStatus;
        this.bookingDate = FUNCTIONS.formatDateTime(bookingDate, "YYYY-MM-DD");
        this.bookingSlot = new BookingSlotModel(bookingSlot.bookingSlotID, bookingSlot.bookingStartTime, bookingSlot.bookingEndTime, bookingSlot.maximumAvailableBookings);
        this.bookingTypeId = bookingTypeId;
        this.batteryPartNumber = batteryPartNumber;
        this.battery = new BatteryModel(battery.stockNumber, battery.memberPrice, battery.basePrice, battery.description, battery.warranty, battery.aH20hr, battery.rcMins, battery.ccAmps, battery.batteryTechnology, battery.batteryType, battery.postType, battery.requirements, battery.imageID);
        this.purchaserInformation = new PurchaserInformationModel(purchaserInformation.email, purchaserInformation.firstName, purchaserInformation.lastName, purchaserInformation.memberId, purchaserInformation.phoneNumber);
        this.attendeeInformation = new AttendeeInformationModel(attendeeInformation.firstName, attendeeInformation.lastName, attendeeInformation.phoneNumber, attendeeInformation.sameAsPurchaser);
        this.location = new LocationModel(location.fullAddress, location.streetAddress, location.suburb, location.state, location.postcode, location.unitType, location.unit, location.streetNumber, location.street1, location.street2, location.additionalNotes, location.externalLocationID);
        this.vehicleInformation = new VehicleModel(vehicleInformation.colour, vehicleInformation.make, vehicleInformation.model, vehicleInformation.registrationNumber, vehicleInformation.variant, vehicleInformation.vehicleId, vehicleInformation.yearGrouping);
        this.promotionalCode = promotionalCode;
    }
    return BookingRequestModel;
}());
exports.default = BookingRequestModel;
