"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocationReset = exports.ClearAddressRetrieve = exports.AddressReverseGeocodeSuccess = exports.AddressManualSelected = exports.AddressManualSetSuccess = exports.AddressContinueSuccess = exports.AddressRetrieveSuccess = exports.AddressFindSuccess = exports.selectNextAvailableDate = exports.selectNextAvailableAppointment = exports.selectPostcodeValid = exports.selectManualAddress = exports.selectFullAddress = exports.selectAddress = exports.selectAllAddresses = exports.locationSlice = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
//Client Managed Text
var constants_1 = require("@/utils/constants");
var $locationData = constants_1.WINDOW_MESSAGES === null || constants_1.WINDOW_MESSAGES === void 0 ? void 0 : constants_1.WINDOW_MESSAGES.Location;
var $nextAppointment = $locationData === null || $locationData === void 0 ? void 0 : $locationData.NextAppointment;
var addressObj = {
    fullAddress: "",
    streetAddress: "",
    suburb: "",
    state: "",
    postcode: null,
    unitType: "",
    unit: "",
    streetNumber: "",
    street1: "",
    street2: "",
};
var initialState = {
    addressNames: [],
    addressObj: {
        fullAddress: "",
        streetAddress: "",
        suburb: "",
        state: "",
        postcode: null,
        unitType: "",
        unit: "",
        streetNumber: "",
        street1: "",
        street2: "",
    },
    isManualAddressSelected: false,
    isPostcodeValid: "",
    nextAvailableAppointment: "",
    nextAvailableDate: "",
};
exports.locationSlice = (0, toolkit_1.createSlice)({
    name: "location",
    initialState: initialState,
    reducers: {
        AddressFindSuccess: function (state, action) {
            return __assign(__assign({}, state), { addressNames: action.payload });
        },
        AddressRetrieveSuccess: function (state, action) {
            var payload = action.payload;
            var selectedAddressObj = (payload === null || payload === void 0 ? void 0 : payload.data) ? payload.data : payload.addressObj;
            var isPostcodeValid = payload.isPostcodeValid ? payload.isPostcodeValid : state.isPostcodeValid;
            return __assign(__assign({}, state), { addressNames: [], addressObj: {
                    fullAddress: selectedAddressObj.fullAddress,
                    streetAddress: selectedAddressObj.streetAddress,
                    suburb: selectedAddressObj.suburb,
                    state: selectedAddressObj.state,
                    postcode: selectedAddressObj.postcode,
                    unitType: selectedAddressObj.unitType,
                    unit: selectedAddressObj.unit,
                    streetNumber: selectedAddressObj.streetNumber,
                    street1: selectedAddressObj.street1,
                    street2: selectedAddressObj.street2,
                }, isPostcodeValid: isPostcodeValid });
        },
        AddressContinueSuccess: function (state, action) {
            var _a;
            var payload = action.payload;
            var appointmentData = (_a = payload === null || payload === void 0 ? void 0 : payload.data) === null || _a === void 0 ? void 0 : _a.nextAvailableAppointment;
            var updatedAppointDetail, nextAvailableDate;
            if (appointmentData) {
                updatedAppointDetail = $nextAppointment.replace("%DATE%", "<b>".concat(appointmentData.Date, "</b>")).replace("%TIME%", "<b>".concat(appointmentData.Time, "</b>"));
                nextAvailableDate = appointmentData.NextAvailableDate;
            }
            return __assign(__assign({}, state), { nextAvailableAppointment: updatedAppointDetail ? updatedAppointDetail : null, nextAvailableDate: nextAvailableDate ? new Date(nextAvailableDate).toISOString() : "", isPostcodeValid: payload.data.isPostcodeValid });
        },
        AddressManualSetSuccess: function (state, action) {
            var payload = action.payload;
            var manualAddressObj = payload.addressObj ? payload.addressObj : payload;
            //const isPostcodeValid = payload.isPostcodeValid ? payload.isPostcodeValid : state.isPostcodeValid;
            return __assign(__assign({}, state), { addressObj: {
                    fullAddress: "".concat(manualAddressObj.streetAddress, ", ").concat(manualAddressObj.suburb, " ").concat(manualAddressObj.state, " ").concat(manualAddressObj.postcode),
                    streetAddress: manualAddressObj.streetAddress,
                    suburb: manualAddressObj.suburb,
                    state: manualAddressObj.state,
                    postcode: manualAddressObj.postcode,
                } });
        },
        AddressManualSelected: function (state, action) {
            return __assign(__assign({}, state), { isManualAddressSelected: action.payload });
        },
        AddressReverseGeocodeSuccess: function (state, action) {
            var payload = action.payload;
            var selectedAddressObj = payload;
            return __assign(__assign({}, state), { addressNames: [], addressObj: {
                    fullAddress: "".concat(selectedAddressObj.fullAddress),
                    suburb: selectedAddressObj.suburb,
                    state: selectedAddressObj.state,
                    postcode: selectedAddressObj.postcode,
                    unitType: selectedAddressObj.unitType,
                    unit: selectedAddressObj.unit,
                    streetNumber: selectedAddressObj.streetNumber,
                    street1: selectedAddressObj.street1,
                    street2: selectedAddressObj.street2,
                } });
        },
        ClearAddressRetrieve: function (state, action) {
            return __assign(__assign({}, state), { addressObj: addressObj, nextAvailableAppointment: "", nextAvailableDate: "", isPostcodeValid: "" });
        },
        LocationReset: function (state, action) {
            return __assign({}, initialState);
        },
    },
});
//Selectors
var selectAllAddresses = function (state) { return state.location.addressNames; };
exports.selectAllAddresses = selectAllAddresses;
var selectAddress = function (state) { return state.location.addressObj; };
exports.selectAddress = selectAddress;
var selectFullAddress = function (state) { return state.location.addressObj.fullAddress; };
exports.selectFullAddress = selectFullAddress;
var selectManualAddress = function (state) { return state.location.isManualAddressSelected; };
exports.selectManualAddress = selectManualAddress;
var selectPostcodeValid = function (state) { return state.location.isPostcodeValid; };
exports.selectPostcodeValid = selectPostcodeValid;
var selectNextAvailableAppointment = function (state) { return state.location.nextAvailableAppointment; };
exports.selectNextAvailableAppointment = selectNextAvailableAppointment;
var selectNextAvailableDate = function (state) { return state.location.nextAvailableDate; };
exports.selectNextAvailableDate = selectNextAvailableDate;
//Actions
exports.AddressFindSuccess = (_a = exports.locationSlice.actions, _a.AddressFindSuccess), exports.AddressRetrieveSuccess = _a.AddressRetrieveSuccess, exports.AddressContinueSuccess = _a.AddressContinueSuccess, exports.AddressManualSetSuccess = _a.AddressManualSetSuccess, exports.AddressManualSelected = _a.AddressManualSelected, exports.AddressReverseGeocodeSuccess = _a.AddressReverseGeocodeSuccess, exports.ClearAddressRetrieve = _a.ClearAddressRetrieve, exports.LocationReset = _a.LocationReset;
exports.default = exports.locationSlice.reducer;
