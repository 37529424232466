"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
//AAANT DOES NOT HAVE BATTERY ICONS
var BatteryIcons = [
    {
        label: "Delivery",
        icon: "aant-delivery",
    },
    {
        label: "Installation",
        icon: "aant-wrench",
    },
    {
        label: "Battery Recycling",
        icon: "aant-recycle",
    },
];
exports.default = BatteryIcons;
