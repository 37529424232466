"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BatteryClearMembershipSaga = exports.BatteryUpdateBookingTypeSaga = exports.BatteryCollectPartNumbersSaga = exports.BatteryContinueSaga = exports.BatteryMembershipSaga = exports.BatteryFetchByStockNumberSaga = exports.BatteryFetchStartSaga = exports.updateSubTitleAfterCollectPartNumbers = void 0;
//React + Pkgs
var effects_1 = require("@redux-saga/core/effects");
//Redux
var globalSlice_1 = require("../slices/globalSlice");
var vehicleSlice_1 = require("../slices/vehicleSlice");
var batterySlice_1 = require("../slices/batterySlice");
var ActionTypes_1 = require("./ActionTypes");
//Funcs
var FUNCTIONS = __importStar(require("../../utils"));
var CONSTANTS = __importStar(require("../../utils/constants"));
var stepsSaga_1 = require("./stepsSaga");
var stepsSlice_1 = require("../slices/stepsSlice");
var toolkit_1 = require("@reduxjs/toolkit");
var functions_root_1 = require("../../brand-settings/_rootConfig/functions-root");
exports.updateSubTitleAfterCollectPartNumbers = (0, toolkit_1.createAction)(ActionTypes_1.SagaActions.UPDATE_SUBTITLE);
function BatteryFetchStartSaga(action) {
    var vehicleId, apiUrl, result, error_1;
    var _a, _b, _c, _d, _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0: return [4 /*yield*/, (0, effects_1.select)(vehicleSlice_1.selectVehicleId)];
            case 1:
                vehicleId = _f.sent();
                apiUrl = "".concat(process.env.ENV_API_BATTERIES, "?vehicleId=").concat(vehicleId);
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({
                        loadingStatus: CONSTANTS.LOADING_STATUS.loading,
                        loadingCall: CONSTANTS.LOADING_CALL.batteryFetchStart,
                    }))];
            case 2:
                _f.sent();
                _f.label = 3;
            case 3:
                _f.trys.push([3, 12, , 15]);
                return [4 /*yield*/, (0, effects_1.call)(function () {
                        return FUNCTIONS.callApiUrl({
                            url: apiUrl,
                            method: "get",
                        });
                    })];
            case 4:
                result = _f.sent();
                if (!(((_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.status) === "SUCCESS" && ((_b = result === null || result === void 0 ? void 0 : result.data) === null || _b === void 0 ? void 0 : _b.results.length) > 0)) return [3 /*break*/, 8];
                return [4 /*yield*/, (0, effects_1.put)((0, batterySlice_1.BatteryFetchStart)({
                        data: (_c = result === null || result === void 0 ? void 0 : result.data) === null || _c === void 0 ? void 0 : _c.results,
                        vehicleRegRequiredMessageBox: (_d = result === null || result === void 0 ? void 0 : result.data) === null || _d === void 0 ? void 0 : _d.vehicle.registrationRequired,
                        vehicleVariationInFitment: (_e = result === null || result === void 0 ? void 0 : result.data) === null || _e === void 0 ? void 0 : _e.vehicle.variationInFitment,
                    }))];
            case 5:
                _f.sent();
                return [4 /*yield*/, (0, effects_1.delay)(CONSTANTS._loadBlockerDelay)];
            case 6:
                _f.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.loaded }))];
            case 7:
                _f.sent();
                return [3 /*break*/, 11];
            case 8: return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.FailureMessage)(CONSTANTS.GENERAL_ERROR_MESSAGE))];
            case 9:
                _f.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.error, loadingCall: CONSTANTS.LOADING_CALL.batteryFetchStart }))];
            case 10:
                _f.sent();
                _f.label = 11;
            case 11: return [3 /*break*/, 15];
            case 12:
                error_1 = _f.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.FailureMessage)(error_1.message))];
            case 13:
                _f.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.error }))];
            case 14:
                _f.sent();
                return [3 /*break*/, 15];
            case 15: return [2 /*return*/];
        }
    });
}
exports.BatteryFetchStartSaga = BatteryFetchStartSaga;
function BatteryFetchByStockNumberSaga(action) {
    var vehicleId, _a, stockNumber, warranty, bookingTypeId, apiUrl, result, batteryObj, error_2;
    var _b, _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0: return [4 /*yield*/, (0, effects_1.select)(vehicleSlice_1.selectVehicleId)];
            case 1:
                vehicleId = _e.sent();
                _a = action.payload, stockNumber = _a.stockNumber, warranty = _a.warranty, bookingTypeId = _a.bookingTypeId;
                apiUrl = "".concat(process.env.ENV_API_BATTERIES, "?vehicleId=").concat(vehicleId);
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({
                        loadingStatus: CONSTANTS.LOADING_STATUS.loading,
                        loadingCall: CONSTANTS.LOADING_CALL.batteryFetchStart,
                    }))];
            case 2:
                _e.sent();
                _e.label = 3;
            case 3:
                _e.trys.push([3, 12, , 15]);
                return [4 /*yield*/, (0, effects_1.call)(function () {
                        return FUNCTIONS.callApiUrl({
                            url: apiUrl,
                            method: "get",
                        });
                    })];
            case 4:
                result = _e.sent();
                if (!(((_b = result === null || result === void 0 ? void 0 : result.data) === null || _b === void 0 ? void 0 : _b.status) === "SUCCESS" && ((_c = result === null || result === void 0 ? void 0 : result.data) === null || _c === void 0 ? void 0 : _c.results.length) > 0)) return [3 /*break*/, 8];
                batteryObj = (_d = result === null || result === void 0 ? void 0 : result.data) === null || _d === void 0 ? void 0 : _d.results.find(function (item) { return item.stockNumber === stockNumber && item.warranty === warranty; });
                return [4 /*yield*/, (0, effects_1.put)((0, batterySlice_1.BatteryCardSelectedObj)({
                        data: batteryObj,
                        bookingTypeId: bookingTypeId,
                    }))];
            case 5:
                _e.sent();
                return [4 /*yield*/, (0, effects_1.delay)(CONSTANTS._loadBlockerDelay)];
            case 6:
                _e.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.loaded }))];
            case 7:
                _e.sent();
                return [3 /*break*/, 11];
            case 8: return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.FailureMessage)(CONSTANTS.GENERAL_ERROR_MESSAGE))];
            case 9:
                _e.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.error, loadingCall: CONSTANTS.LOADING_CALL.batteryFetchStart }))];
            case 10:
                _e.sent();
                _e.label = 11;
            case 11: return [3 /*break*/, 15];
            case 12:
                error_2 = _e.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.FailureMessage)(error_2.message))];
            case 13:
                _e.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.error }))];
            case 14:
                _e.sent();
                return [3 /*break*/, 15];
            case 15: return [2 /*return*/];
        }
    });
}
exports.BatteryFetchByStockNumberSaga = BatteryFetchByStockNumberSaga;
function BatteryMembershipSaga(action) {
    var membershipnumber, apiUrl, batteryObj, bookingTypeId, result, priceSummary, error_3;
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    return __generator(this, function (_m) {
        switch (_m.label) {
            case 0:
                membershipnumber = action.payload;
                apiUrl = "".concat(process.env.ENV_API_MEMBERSHIP, "?membershipnumber=").concat(membershipnumber);
                return [4 /*yield*/, (0, effects_1.select)(batterySlice_1.selectBatteryCardObj)];
            case 1:
                batteryObj = _m.sent();
                return [4 /*yield*/, (0, effects_1.select)(batterySlice_1.selectBookingTypeId)];
            case 2:
                bookingTypeId = _m.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({
                        loadingStatus: CONSTANTS.LOADING_STATUS.loading,
                        loadingCall: CONSTANTS.LOADING_CALL.batteryMembership,
                    }))];
            case 3:
                _m.sent();
                _m.label = 4;
            case 4:
                _m.trys.push([4, 24, , 28]);
                return [4 /*yield*/, (0, effects_1.call)(function () {
                        return FUNCTIONS.callApiUrl({
                            url: apiUrl,
                            method: "get",
                        });
                    })];
            case 5:
                result = _m.sent();
                if (!(((_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.status) === "SUCCESS")) return [3 /*break*/, 16];
                if (!(((_b = result === null || result === void 0 ? void 0 : result.data) === null || _b === void 0 ? void 0 : _b.isMembershipValid) == "YES")) return [3 /*break*/, 9];
                return [4 /*yield*/, (0, effects_1.put)((0, batterySlice_1.BatteryMembership)({
                        isMembershipValid: (_c = result === null || result === void 0 ? void 0 : result.data) === null || _c === void 0 ? void 0 : _c.isMembershipValid,
                        isMembershipEligible: (_d = result === null || result === void 0 ? void 0 : result.data) === null || _d === void 0 ? void 0 : _d.isMembershipEligible,
                        memberId: membershipnumber,
                    }))];
            case 6:
                _m.sent();
                return [4 /*yield*/, (0, effects_1.delay)(CONSTANTS._loadBlockerDelay)];
            case 7:
                _m.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.loaded }))];
            case 8:
                _m.sent();
                _m.label = 9;
            case 9:
                if (!(((_e = result === null || result === void 0 ? void 0 : result.data) === null || _e === void 0 ? void 0 : _e.isMembershipEligible) == "NO")) return [3 /*break*/, 13];
                return [4 /*yield*/, (0, effects_1.put)((0, batterySlice_1.BatteryMembership)({
                        isMembershipValid: (_f = result === null || result === void 0 ? void 0 : result.data) === null || _f === void 0 ? void 0 : _f.isMembershipValid,
                        isMembershipEligible: (_g = result === null || result === void 0 ? void 0 : result.data) === null || _g === void 0 ? void 0 : _g.isMembershipEligible,
                        memberId: membershipnumber,
                    }))];
            case 10:
                _m.sent();
                return [4 /*yield*/, (0, effects_1.delay)(CONSTANTS._loadBlockerDelay)];
            case 11:
                _m.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.error }))];
            case 12:
                _m.sent();
                _m.label = 13;
            case 13:
                if (!Object.keys(batteryObj).length) return [3 /*break*/, 15];
                priceSummary = (0, functions_root_1.updateSubTitlePriceSummary)(batteryObj, (_h = result === null || result === void 0 ? void 0 : result.data) === null || _h === void 0 ? void 0 : _h.isMembershipValid, bookingTypeId);
                // Dispatch the updateBatteryStepSubtitle action with the updated batterySummary
                return [4 /*yield*/, (0, effects_1.put)((0, stepsSlice_1.UpdateBookingStepSubTitle)({ stepId: 'battery', subTitle: "".concat((batteryObj === null || batteryObj === void 0 ? void 0 : batteryObj.batteryType) ? batteryObj === null || batteryObj === void 0 ? void 0 : batteryObj.batteryType : "", " ").concat(batteryObj === null || batteryObj === void 0 ? void 0 : batteryObj.warranty, " year warranty, ").concat(priceSummary) }))];
            case 14:
                // Dispatch the updateBatteryStepSubtitle action with the updated batterySummary
                _m.sent();
                _m.label = 15;
            case 15: return [3 /*break*/, 20];
            case 16: return [4 /*yield*/, (0, effects_1.put)((0, batterySlice_1.BatteryMembership)({
                    isMembershipValid: (_j = result === null || result === void 0 ? void 0 : result.data) === null || _j === void 0 ? void 0 : _j.isMembershipValid,
                    isMembershipEligible: (_k = result === null || result === void 0 ? void 0 : result.data) === null || _k === void 0 ? void 0 : _k.isMembershipEligible,
                }))];
            case 17:
                _m.sent();
                return [4 /*yield*/, (0, effects_1.delay)(CONSTANTS._loadBlockerDelay)];
            case 18:
                _m.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.error }))];
            case 19:
                _m.sent();
                _m.label = 20;
            case 20:
                if (!(((_l = result === null || result === void 0 ? void 0 : result.data) === null || _l === void 0 ? void 0 : _l.status) !== "SUCCESS")) return [3 /*break*/, 23];
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.FailureMessage)(CONSTANTS.GENERAL_ERROR_MESSAGE))];
            case 21:
                _m.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.error }))];
            case 22:
                _m.sent();
                _m.label = 23;
            case 23: return [3 /*break*/, 28];
            case 24:
                error_3 = _m.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, batterySlice_1.BatteryMembership)({
                        isMembershipValid: "",
                    }))];
            case 25:
                _m.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.FailureMessage)(error_3.message))];
            case 26:
                _m.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.error }))];
            case 27:
                _m.sent();
                return [3 /*break*/, 28];
            case 28: return [2 /*return*/];
        }
    });
}
exports.BatteryMembershipSaga = BatteryMembershipSaga;
function BatteryContinueSaga(action) {
    var _a, data, bookingTypeId, isMembershipValid, apiUrl, result, priceSummary, subtitle, error_4;
    var _b, _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _a = action.payload, data = _a.data, bookingTypeId = _a.bookingTypeId;
                return [4 /*yield*/, (0, effects_1.select)(batterySlice_1.selectMembershipStatus)];
            case 1:
                isMembershipValid = _e.sent();
                apiUrl = "".concat(process.env.ENV_API_BATTERY_CONTINUE);
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({
                        loadingStatus: CONSTANTS.LOADING_STATUS.loading,
                        loadingCall: CONSTANTS.LOADING_CALL.batteryContinue,
                    }))];
            case 2:
                _e.sent();
                _e.label = 3;
            case 3:
                _e.trys.push([3, 14, , 17]);
                return [4 /*yield*/, (0, effects_1.call)(function () {
                        return FUNCTIONS.callApiUrl({
                            url: apiUrl,
                            method: "get",
                        });
                    })];
            case 4:
                result = _e.sent();
                if (!(((_b = result === null || result === void 0 ? void 0 : result.data) === null || _b === void 0 ? void 0 : _b.status) === "SUCCESS" && ((_c = result === null || result === void 0 ? void 0 : result.data) === null || _c === void 0 ? void 0 : _c.isBatteryAvailable) === "YES")) return [3 /*break*/, 10];
                priceSummary = (0, functions_root_1.updateSubTitlePriceSummary)(data, isMembershipValid, bookingTypeId);
                subtitle = "".concat(data.batteryType ? data.batteryType : "", " ").concat(data.warranty, " year warranty, ").concat(priceSummary);
                if (process.env.ENV_BRAND === "raa") {
                    subtitle = "".concat((data === null || data === void 0 ? void 0 : data.batteryType) ? data === null || data === void 0 ? void 0 : data.batteryType : "", " ").concat(data === null || data === void 0 ? void 0 : data.warranty, " year warranty, Part number: ").concat(data === null || data === void 0 ? void 0 : data.stockNumber);
                }
                return [4 /*yield*/, (0, effects_1.put)((0, batterySlice_1.BatteryContinueSuccess)({
                        data: data,
                        bookingTypeId: bookingTypeId,
                        isBatteryAvailable: (_d = result === null || result === void 0 ? void 0 : result.data) === null || _d === void 0 ? void 0 : _d.isBatteryAvailable,
                    }))];
            case 5:
                _e.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, stepsSlice_1.UpdateBookingStepSubTitle)({
                        stepId: 'battery',
                        subTitle: subtitle,
                    }))];
            case 6:
                _e.sent();
                return [4 /*yield*/, (0, effects_1.delay)(CONSTANTS._loadBlockerDelay)];
            case 7:
                _e.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.loaded }))];
            case 8:
                _e.sent();
                return [4 /*yield*/, (0, effects_1.call)(stepsSaga_1.incrementStepSaga)];
            case 9:
                _e.sent();
                return [3 /*break*/, 13];
            case 10: return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.FailureMessage)(CONSTANTS.GENERAL_ERROR_MESSAGE))];
            case 11:
                _e.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.error }))];
            case 12:
                _e.sent();
                _e.label = 13;
            case 13: return [3 /*break*/, 17];
            case 14:
                error_4 = _e.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.FailureMessage)(error_4.message))];
            case 15:
                _e.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.error }))];
            case 16:
                _e.sent();
                return [3 /*break*/, 17];
            case 17: return [2 /*return*/];
        }
    });
}
exports.BatteryContinueSaga = BatteryContinueSaga;
//AANT
function BatteryCollectPartNumbersSaga(action) {
    var batteries, partNumbers;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.select)(batterySlice_1.selectBatteryStart)];
            case 1:
                batteries = _a.sent();
                partNumbers = batteries.map(function (part) { return part.stockNumber; });
                // Dispatch the updateBatteryStepSubtitle action with the updated batterySummary
                return [4 /*yield*/, (0, effects_1.put)((0, stepsSlice_1.UpdateBookingStepSubTitle)({ stepId: 'battery', subTitle: "Part No. " + partNumbers.join(', ') }))];
            case 2:
                // Dispatch the updateBatteryStepSubtitle action with the updated batterySummary
                _a.sent();
                return [4 /*yield*/, (0, effects_1.call)(stepsSaga_1.incrementStepSaga)];
            case 3:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.BatteryCollectPartNumbersSaga = BatteryCollectPartNumbersSaga;
function BatteryUpdateBookingTypeSaga(action) {
    var bookingTypeId, batteryObj, isMembershipValid, priceSummary;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                bookingTypeId = action.payload.bookingTypeId;
                return [4 /*yield*/, (0, effects_1.put)((0, batterySlice_1.BatteryBookingType)({ bookingTypeId: bookingTypeId }))];
            case 1:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.select)(batterySlice_1.selectBatteryCardObj)];
            case 2:
                batteryObj = _a.sent();
                if (Object.keys(batteryObj).length === 0 && batteryObj.constructor === Object)
                    return [2 /*return*/];
                return [4 /*yield*/, (0, effects_1.select)(batterySlice_1.selectMembershipStatus)];
            case 3:
                isMembershipValid = _a.sent();
                priceSummary = (0, functions_root_1.updateSubTitlePriceSummary)(batteryObj, isMembershipValid, bookingTypeId);
                return [4 /*yield*/, (0, effects_1.put)((0, stepsSlice_1.UpdateBookingStepSubTitle)({ stepId: 'battery', subTitle: "".concat((batteryObj === null || batteryObj === void 0 ? void 0 : batteryObj.batteryType) ? batteryObj === null || batteryObj === void 0 ? void 0 : batteryObj.batteryType : "", " ").concat(batteryObj === null || batteryObj === void 0 ? void 0 : batteryObj.warranty, " year warranty, ").concat(priceSummary) }))];
            case 4:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.BatteryUpdateBookingTypeSaga = BatteryUpdateBookingTypeSaga;
function BatteryClearMembershipSaga(action) {
    var bookingTypeId, batteryObj, isMembershipValid, priceSummary;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.put)((0, batterySlice_1.ClearMembership)())];
            case 1:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.delay)(100)];
            case 2:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.select)(batterySlice_1.selectBookingTypeId)];
            case 3:
                bookingTypeId = _a.sent();
                return [4 /*yield*/, (0, effects_1.select)(batterySlice_1.selectBatteryCardObj)];
            case 4:
                batteryObj = _a.sent();
                return [4 /*yield*/, (0, effects_1.select)(batterySlice_1.selectMembershipStatus)];
            case 5:
                isMembershipValid = _a.sent();
                priceSummary = (0, functions_root_1.updateSubTitlePriceSummary)(batteryObj, isMembershipValid, bookingTypeId);
                if (!(Object.keys(batteryObj).length > 0)) return [3 /*break*/, 7];
                return [4 /*yield*/, (0, effects_1.put)((0, stepsSlice_1.UpdateBookingStepSubTitle)({ stepId: 'battery', subTitle: "".concat((batteryObj === null || batteryObj === void 0 ? void 0 : batteryObj.batteryType) ? batteryObj === null || batteryObj === void 0 ? void 0 : batteryObj.batteryType : "", " ").concat(batteryObj === null || batteryObj === void 0 ? void 0 : batteryObj.warranty, " year warranty, ").concat(priceSummary) }))];
            case 6:
                _a.sent();
                _a.label = 7;
            case 7: return [2 /*return*/];
        }
    });
}
exports.BatteryClearMembershipSaga = BatteryClearMembershipSaga;
//ALL SAGAS
function batterySagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(ActionTypes_1.SagaActions.BATTERY_FETCH_START_SAGA, BatteryFetchStartSaga)];
            case 1:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.takeLatest)(ActionTypes_1.SagaActions.BATTERY_FETCH_BY_STOCK_NUMBER_SAGA, BatteryFetchByStockNumberSaga)];
            case 2:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.takeLatest)(ActionTypes_1.SagaActions.BATTERY_MEMBERSHIP_SAGA, BatteryMembershipSaga)];
            case 3:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.takeLatest)(ActionTypes_1.SagaActions.BATTERY_CLEAR_MEMBERSHIP_SAGA, BatteryClearMembershipSaga)];
            case 4:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.takeLatest)(ActionTypes_1.SagaActions.BATTERY_CONTINUE_SAGA, BatteryContinueSaga)];
            case 5:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.takeLatest)(ActionTypes_1.SagaActions.BATTERY_COLLECT_PART_NUMBERS, BatteryCollectPartNumbersSaga)];
            case 6:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.takeLatest)(ActionTypes_1.SagaActions.BATTERY_UPDATE_BOOKING_TYPE, BatteryUpdateBookingTypeSaga)];
            case 7:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.default = batterySagas;
