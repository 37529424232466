"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FetchBookingSuccess = exports.selectBookingData = exports.bookingSlice = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
//Store Data based on Appointment Screen
var initialState = {
    bookingData: [],
};
exports.bookingSlice = (0, toolkit_1.createSlice)({
    name: "booking",
    initialState: initialState,
    reducers: {
        FetchBookingSuccess: function (state, action) {
            return __assign(__assign({}, state), { bookingData: action.payload });
        },
    },
});
//Selectors
var selectBookingData = function (state) { return state.booking.bookingData; };
exports.selectBookingData = selectBookingData;
//Actions
exports.FetchBookingSuccess = exports.bookingSlice.actions.FetchBookingSuccess;
exports.default = exports.bookingSlice.reducer;
