"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
//AAANT DOES NOT HAVE BATTERY ICONS
var BatteryIcons = [
    {
        label: "Installation",
        icon: "raa-vehicle",
    },
    {
        label: "Delivery",
        icon: "raa-appointment",
    },
    {
        label: "Recycling of old battery",
        icon: "raa-battery-recycle",
    },
];
exports.default = BatteryIcons;
