"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MemberNumberValidation = exports.StateOptions = void 0;
var forms_1 = require("../nrma/forms");
var forms_2 = require("../racv/forms");
var forms_3 = require("../ract/forms");
var forms_4 = require("../racq/forms");
var forms_5 = require("../racwa/forms");
var forms_6 = require("../aant/forms");
var forms_7 = require("../raa/forms");
var brandStateOptionsMap = {
    nrma: forms_1.StateOptions,
    racv: forms_2.StateOptions,
    ract: forms_3.StateOptions,
    racq: forms_4.StateOptions,
    racwa: forms_5.StateOptions,
    aant: forms_6.StateOptions,
    raa: forms_7.StateOptions,
};
var memberNumberValidationMap = {
    nrma: forms_1.MemberNumberValidation,
    racv: forms_2.MemberNumberValidation,
    ract: forms_3.MemberNumberValidation,
    racq: forms_4.MemberNumberValidation,
    racwa: forms_5.MemberNumberValidation,
    aant: forms_6.MemberNumberValidation,
    raa: forms_7.MemberNumberValidation,
};
exports.StateOptions = brandStateOptionsMap[process.env.ENV_BRAND];
exports.MemberNumberValidation = memberNumberValidationMap[process.env.ENV_BRAND];
