"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.config = void 0;
var constants_1 = require("@/utils/constants");
// RACWA Brand Config
exports.config = {
    Location: __assign({}, constants_1.WINDOW_MESSAGES.Location),
    Vehicle: __assign(__assign({}, constants_1.WINDOW_MESSAGES.Vehicle), { RegoCancelButton: {
            label: "Back",
            showRegoCancelButton: true,
        }, RegoConfirmButton: {
            label: "Confirm vehicle",
            tertiary: true,
            icon: "",
        } }),
    Battery: __assign(__assign({}, constants_1.WINDOW_MESSAGES.Battery), { SelectBatteryButton: {
            label: "Select Battery",
            showSelectBatteryButton: true,
            tertiary: false,
            isMemberValidAndEligibleOnClick: null,
        }, BatteryIcons: [
            {
                label: "Installation",
                icon: "racwa-installation",
            },
            {
                label: "Delivery",
                icon: "racwa-time",
            },
            {
                label: "Battery Recycling",
                icon: "racwa-recycling",
            },
        ], Confirmation: {
            showAllPrices: false,
            memberPriceLabel: "Your Member price",
            nonMemberPriceLabel: "Non-member price",
        } }),
    Appointment: __assign(__assign({}, constants_1.WINDOW_MESSAGES.Appointment), { AdditionalNotesLabel: "Help us locate your car", PurchaserToggleButtonsType: "radio", SubmitButton: {
            label: "Book now",
            tertiary: true,
        } }),
    EditBooking: {
        Heading: "Edit booking",
    },
    Settings: __assign({}, constants_1.WINDOW_MESSAGES.Settings),
    Icons: {
        IconsMultiColor: true,
        InfoCircle: "racwa-info-circle",
        Alert: "racwa-info-circle",
        Warning: "racwa-alert",
        Close: "racwa-close",
        CloseCircle: "close-circle",
        Battery: "racwa-battery",
        Vehicle: "racwa-vehicle",
        EditOutline: "edit-outline",
        Calendar: "racwa-calender",
        BookingSuccess: "racwa-thumb",
    },
};
