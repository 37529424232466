"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MemberNumberValidation = exports.StateOptions = void 0;
exports.StateOptions = [
    { value: "QLD", label: "Queensland" },
    { value: "", label: "--" },
    { value: "NSW", label: "New South Wales" },
    { value: "ACT", label: "Australian Capital Territory" },
    { value: "NT", label: "Northern Territory" },
    { value: "VIC", label: "Victoria" },
    { value: "TAS", label: "Tasmania" },
    { value: "SA", label: "South Australia" },
    { value: "WA", label: "Western Australia" },
];
exports.MemberNumberValidation = {
    min: {
        value: 16,
        message: "Invalid member number, not enough characters entered",
    },
    max: {
        value: 16,
        message: "Max character limit exceeded",
    },
};
