"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressContinueSaga = exports.AddressReverseGeocodeSaga = exports.AddressRetrieveSaga = exports.AddressFindSaga = void 0;
//React + Pkgs
var effects_1 = require("@redux-saga/core/effects");
//Redux
var locationSlice_1 = require("@/redux/slices/locationSlice");
var globalSlice_1 = require("@/redux/slices/globalSlice");
var ActionTypes_1 = require("@/redux/sagas/ActionTypes");
//Funcs
var FUNCTIONS = __importStar(require("@/utils"));
var CONSTANTS = __importStar(require("@/utils/constants"));
var stepsSlice_1 = require("@/redux/slices/stepsSlice");
//ADDRESS FIND
function AddressFindSaga(action) {
    var country, apiUrl, result, error_1;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                country = "AU";
                apiUrl = "".concat(process.env.ENV_API_ADDRESS_FIND, "?country=").concat(country, "&fullAddress=").concat(action.payload);
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({
                        loadingStatus: CONSTANTS.LOADING_STATUS.loadingAutoComplete,
                        loadingCall: CONSTANTS.LOADING_CALL.locationFind,
                    }))];
            case 1:
                _d.sent();
                _d.label = 2;
            case 2:
                _d.trys.push([2, 11, , 14]);
                return [4 /*yield*/, (0, effects_1.call)(function () {
                        return FUNCTIONS.callApiUrl({
                            url: apiUrl,
                            method: "get",
                        });
                    })];
            case 3:
                result = _d.sent();
                if (!(((_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.status) === "SUCCESS" && ((_b = result === null || result === void 0 ? void 0 : result.data) === null || _b === void 0 ? void 0 : _b.results.length) > 0)) return [3 /*break*/, 7];
                return [4 /*yield*/, (0, effects_1.put)((0, locationSlice_1.AddressFindSuccess)((_c = result === null || result === void 0 ? void 0 : result.data) === null || _c === void 0 ? void 0 : _c.results))];
            case 4:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.delay)(CONSTANTS._loadBlockerDelay)];
            case 5:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.loaded }))];
            case 6:
                _d.sent();
                return [3 /*break*/, 10];
            case 7: return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.FailureMessage)("No results found"))];
            case 8:
                _d.sent(); //TODO: let the form field handle message
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.noResults }))];
            case 9:
                _d.sent();
                _d.label = 10;
            case 10: return [3 /*break*/, 14];
            case 11:
                error_1 = _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.FailureMessage)(error_1.message))];
            case 12:
                _d.sent(); //TODO: let the form field handle message
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.error }))];
            case 13:
                _d.sent();
                return [3 /*break*/, 14];
            case 14: return [2 /*return*/];
        }
    });
}
exports.AddressFindSaga = AddressFindSaga;
//ADDRESS RETRIEVE
function AddressRetrieveSaga(action) {
    var addressId, encodeAddressId, apiUrl, result, fullAddress, error_2;
    var _a, _b, _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                addressId = action.payload.addressId;
                encodeAddressId = encodeURI(addressId);
                apiUrl = "".concat(process.env.ENV_API_ADDRESS_RETRIEVE, "?id=").concat(encodeAddressId);
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({
                        loadingStatus: CONSTANTS.LOADING_STATUS.loading,
                        loadingCall: CONSTANTS.LOADING_CALL.locationRetrieve,
                    }))];
            case 1:
                _e.sent();
                _e.label = 2;
            case 2:
                _e.trys.push([2, 12, , 15]);
                return [4 /*yield*/, (0, effects_1.call)(function () {
                        return FUNCTIONS.callApiUrl({
                            url: apiUrl,
                            method: "get",
                        });
                    })];
            case 3:
                result = _e.sent();
                if (!(((_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.status) === "SUCCESS" && ((_b = result === null || result === void 0 ? void 0 : result.data) === null || _b === void 0 ? void 0 : _b.result) !== null)) return [3 /*break*/, 8];
                fullAddress = ((_c = result === null || result === void 0 ? void 0 : result.data) === null || _c === void 0 ? void 0 : _c.result).fullAddress;
                return [4 /*yield*/, (0, effects_1.put)((0, locationSlice_1.AddressRetrieveSuccess)({
                        data: (_d = result === null || result === void 0 ? void 0 : result.data) === null || _d === void 0 ? void 0 : _d.result,
                    }))];
            case 4:
                _e.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, stepsSlice_1.UpdateBookingStepSubTitle)({
                        stepId: "location",
                        subTitle: fullAddress ? fullAddress : "",
                    }))];
            case 5:
                _e.sent();
                return [4 /*yield*/, (0, effects_1.delay)(CONSTANTS._loadBlockerDelay)];
            case 6:
                _e.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.loaded }))];
            case 7:
                _e.sent();
                return [3 /*break*/, 11];
            case 8: return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.FailureMessage)("No results found"))];
            case 9:
                _e.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.noResults }))];
            case 10:
                _e.sent();
                _e.label = 11;
            case 11: return [3 /*break*/, 15];
            case 12:
                error_2 = _e.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.FailureMessage)(error_2.message))];
            case 13:
                _e.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.error }))];
            case 14:
                _e.sent();
                return [3 /*break*/, 15];
            case 15: return [2 /*return*/];
        }
    });
}
exports.AddressRetrieveSaga = AddressRetrieveSaga;
//ADDRESS REVERSE GEOCODE
function AddressReverseGeocodeSaga(action) {
    var _a, latitude, longitude, apiUrl, result, fullAddress, error_3;
    var _b, _c, _d, _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = action.payload, latitude = _a.latitude, longitude = _a.longitude;
                apiUrl = "".concat(process.env.ENV_API_ADDRESS_REVERSE_GEOCODE, "?latitude=").concat(latitude, "&longitude=").concat(longitude);
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({
                        loadingStatus: CONSTANTS.LOADING_STATUS.loading,
                        loadingCall: CONSTANTS.LOADING_CALL.locationReverseGeo,
                    }))];
            case 1:
                _f.sent();
                _f.label = 2;
            case 2:
                _f.trys.push([2, 12, , 15]);
                return [4 /*yield*/, (0, effects_1.call)(function () {
                        return FUNCTIONS.callApiUrl({
                            url: apiUrl,
                            method: "get",
                        });
                    })];
            case 3:
                result = _f.sent();
                if (!(((_b = result === null || result === void 0 ? void 0 : result.data) === null || _b === void 0 ? void 0 : _b.status) === "SUCCESS" && ((_c = result === null || result === void 0 ? void 0 : result.data) === null || _c === void 0 ? void 0 : _c.result.length) > 0)) return [3 /*break*/, 8];
                fullAddress = ((_d = result === null || result === void 0 ? void 0 : result.data) === null || _d === void 0 ? void 0 : _d.result[0]).fullAddress;
                return [4 /*yield*/, (0, effects_1.put)((0, locationSlice_1.AddressReverseGeocodeSuccess)((_e = result === null || result === void 0 ? void 0 : result.data) === null || _e === void 0 ? void 0 : _e.result[0]))];
            case 4:
                _f.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, stepsSlice_1.UpdateBookingStepSubTitle)({
                        stepId: "location",
                        subTitle: fullAddress ? fullAddress : "",
                    }))];
            case 5:
                _f.sent();
                return [4 /*yield*/, (0, effects_1.delay)(CONSTANTS._loadBlockerDelay)];
            case 6:
                _f.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.loaded }))];
            case 7:
                _f.sent();
                return [3 /*break*/, 11];
            case 8: return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.FailureMessage)(CONSTANTS.GENERAL_ERROR_MESSAGE))];
            case 9:
                _f.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.error }))];
            case 10:
                _f.sent();
                _f.label = 11;
            case 11: return [3 /*break*/, 15];
            case 12:
                error_3 = _f.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.FailureMessage)(error_3.message))];
            case 13:
                _f.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.error }))];
            case 14:
                _f.sent();
                return [3 /*break*/, 15];
            case 15: return [2 /*return*/];
        }
    });
}
exports.AddressReverseGeocodeSaga = AddressReverseGeocodeSaga;
//ADDRESS CONTINUE
function AddressContinueSaga(action) {
    var getAllState, _a, streetAddress, suburb, state, postcode, todayDate, apiUrl, result, error_4;
    var _b, _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0: return [4 /*yield*/, (0, effects_1.select)()];
            case 1:
                getAllState = _e.sent();
                _a = (_b = getAllState === null || getAllState === void 0 ? void 0 : getAllState.location) === null || _b === void 0 ? void 0 : _b.addressObj, streetAddress = _a.streetAddress, suburb = _a.suburb, state = _a.state, postcode = _a.postcode;
                todayDate = FUNCTIONS.formatDateTime(new Date(), "YYYY-MM-DD");
                apiUrl = "".concat(process.env.ENV_API_ADDRESS_CONTINUE, "?street=").concat(streetAddress, "&suburb=").concat(suburb, "&state=").concat(state, "&postcode=").concat(postcode, "&date=").concat(todayDate);
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({
                        loadingStatus: CONSTANTS.LOADING_STATUS.loading,
                        loadingCall: CONSTANTS.LOADING_CALL.locationContinue,
                    }))];
            case 2:
                _e.sent();
                _e.label = 3;
            case 3:
                _e.trys.push([3, 12, , 15]);
                return [4 /*yield*/, (0, effects_1.call)(function () {
                        return FUNCTIONS.callApiUrl({
                            url: apiUrl,
                            method: "get",
                        });
                    })];
            case 4:
                result = _e.sent();
                if (!(((_c = result === null || result === void 0 ? void 0 : result.data) === null || _c === void 0 ? void 0 : _c.status) === "SUCCESS")) return [3 /*break*/, 8];
                return [4 /*yield*/, (0, effects_1.put)((0, locationSlice_1.AddressContinueSuccess)({ data: result === null || result === void 0 ? void 0 : result.data }))];
            case 5:
                _e.sent();
                return [4 /*yield*/, (0, effects_1.delay)(CONSTANTS._loadBlockerDelay)];
            case 6:
                _e.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.loaded }))];
            case 7:
                _e.sent();
                _e.label = 8;
            case 8:
                if (!(((_d = result === null || result === void 0 ? void 0 : result.data) === null || _d === void 0 ? void 0 : _d.status) === "ERROR")) return [3 /*break*/, 11];
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.FailureMessage)(CONSTANTS.GENERAL_ERROR_MESSAGE))];
            case 9:
                _e.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.error }))];
            case 10:
                _e.sent();
                _e.label = 11;
            case 11: return [3 /*break*/, 15];
            case 12:
                error_4 = _e.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.FailureMessage)(error_4.message))];
            case 13:
                _e.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.error }))];
            case 14:
                _e.sent();
                return [3 /*break*/, 15];
            case 15: return [2 /*return*/];
        }
    });
}
exports.AddressContinueSaga = AddressContinueSaga;
function locationSagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(ActionTypes_1.SagaActions.ADDRESS_FIND_SAGA, AddressFindSaga)];
            case 1:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.takeLatest)(ActionTypes_1.SagaActions.ADDRESS_RETRIEVE_SAGA, AddressRetrieveSaga)];
            case 2:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.takeLatest)(ActionTypes_1.SagaActions.ADDRESS_CONTINUE_SAGA, AddressContinueSaga)];
            case 3:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.takeLatest)(ActionTypes_1.SagaActions.ADDRESS_REVERSE_GEOCODE_SAGA, AddressReverseGeocodeSaga)];
            case 4:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.default = locationSagas;
