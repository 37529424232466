"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInterval = exports.useKeyPress = exports.useCreatePortalInBody = exports.useOnClickOutside = void 0;
var react_1 = require("react");
var react_dom_1 = require("react-dom");
var useOnClickOutside = function (ref, handler) {
    (0, react_1.useEffect)(function () {
        var listener = function (event) {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return function () {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, 
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new
    // function on every render that will cause this effect
    // callback/cleanup to run every render. It's not a big deal
    // but to optimize you can wrap handler in useCallback before
    // passing it into this hook.
    [ref, handler]);
};
exports.useOnClickOutside = useOnClickOutside;
var useCreatePortalInBody = function () {
    var wrapperRef = (0, react_1.useRef)(null);
    if (wrapperRef.current === null && typeof document !== "undefined") {
        var div = document.createElement("div");
        div.setAttribute("data-body-portal", "");
        wrapperRef.current = div;
    }
    (0, react_1.useLayoutEffect)(function () {
        var wrapper = wrapperRef.current;
        if (!wrapper || typeof document === "undefined") {
            return;
        }
        document.body.appendChild(wrapper);
        return function () {
            document.body.removeChild(wrapper);
        };
    }, []);
    return function (children) { return wrapperRef.current && (0, react_dom_1.createPortal)(children, wrapperRef.current); };
};
exports.useCreatePortalInBody = useCreatePortalInBody;
var useKeyPress = function (targetKey) {
    var _a = (0, react_1.useState)(false), keyPressed = _a[0], setKeyPressed = _a[1];
    function downHandler(_a) {
        var key = _a.key;
        if (key === targetKey) {
            setKeyPressed(true);
        }
    }
    var upHandler = function (_a) {
        var key = _a.key;
        if (key === targetKey) {
            setKeyPressed(false);
        }
    };
    (0, react_1.useEffect)(function () {
        window.addEventListener("keydown", downHandler);
        window.addEventListener("keyup", upHandler);
        return function () {
            window.removeEventListener("keydown", downHandler);
            window.removeEventListener("keyup", upHandler);
        };
        // eslint-disable-next-line
    });
    return keyPressed;
};
exports.useKeyPress = useKeyPress;
var useInterval = function (callback, delay) {
    var savedCallback = (0, react_1.useRef)();
    // Remember the latest function.
    (0, react_1.useEffect)(function () {
        savedCallback.current = callback;
    }, [callback]);
    // Set up the interval.
    (0, react_1.useEffect)(function () {
        function tick() {
            if (savedCallback.current) {
                savedCallback.current();
            }
        }
        if (delay !== null) {
            var id_1 = setInterval(tick, delay);
            return function () { return clearInterval(id_1); };
        }
    }, [delay]);
};
exports.useInterval = useInterval;
