"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleContinueSaga = exports.VehicleVariantSaga = exports.VehicleYearGroupingSaga = exports.VehicleModelsSearchSaga = exports.VehicleModelsFetchStartSaga = exports.VehicleMakesSearchSaga = exports.VehicleRegoSearchSaga = exports.VehicleMakesFetchStartSaga = void 0;
//React + Pkgs
var effects_1 = require("@redux-saga/core/effects");
//Redux
var vehicleSlice_1 = require("../slices/vehicleSlice");
var ActionTypes_1 = require("./ActionTypes");
var globalSlice_1 = require("../slices/globalSlice");
//Funcs
var FUNCTIONS = __importStar(require("../../utils"));
var CONSTANTS = __importStar(require("../../utils/constants"));
var stepsSaga_1 = require("./stepsSaga");
var types_1 = require("../../brand-settings/_rootConfig/types");
var stepsSlice_1 = require("../slices/stepsSlice");
//FETCH VEHICLE MAKES START
function VehicleMakesFetchStartSaga(action) {
    var apiUrl, result, error_1;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                apiUrl = "".concat(process.env.ENV_API_VEHICLE_MAKE, "?make");
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({
                        loadingStatus: CONSTANTS.LOADING_STATUS.loading,
                        loadingCall: CONSTANTS.LOADING_CALL.vehicleMakesFetchStart,
                    }))];
            case 1:
                _d.sent();
                _d.label = 2;
            case 2:
                _d.trys.push([2, 11, , 15]);
                return [4 /*yield*/, (0, effects_1.call)(function () {
                        return FUNCTIONS.callApiUrl({
                            url: apiUrl,
                            method: "get",
                        });
                    })];
            case 3:
                result = _d.sent();
                if (!(((_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.status) === "SUCCESS" && ((_b = result === null || result === void 0 ? void 0 : result.data) === null || _b === void 0 ? void 0 : _b.makes.length) > 0)) return [3 /*break*/, 7];
                return [4 /*yield*/, (0, effects_1.put)((0, vehicleSlice_1.VehicleMakesFetchStart)((_c = result === null || result === void 0 ? void 0 : result.data) === null || _c === void 0 ? void 0 : _c.makes))];
            case 4:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.delay)(CONSTANTS._loadBlockerDelay)];
            case 5:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.loaded }))];
            case 6:
                _d.sent();
                return [3 /*break*/, 10];
            case 7: return [4 /*yield*/, (0, effects_1.put)((0, vehicleSlice_1.VehicleMakesFetchStart)([]))];
            case 8:
                _d.sent();
                //yield put(FailureMessage("Sorry, no items found for vehicle make"));
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.noResults }))];
            case 9:
                //yield put(FailureMessage("Sorry, no items found for vehicle make"));
                _d.sent();
                _d.label = 10;
            case 10: return [3 /*break*/, 15];
            case 11:
                error_1 = _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, vehicleSlice_1.VehicleMakesFetchStart)([]))];
            case 12:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.FailureMessage)(error_1.message))];
            case 13:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.error }))];
            case 14:
                _d.sent();
                return [3 /*break*/, 15];
            case 15: return [2 /*return*/];
        }
    });
}
exports.VehicleMakesFetchStartSaga = VehicleMakesFetchStartSaga;
//VEHICLE REGO SEARCH
function VehicleRegoSearchSaga(action) {
    var registrationNumber, state, apiUrl, result, _a, vehicleSummary, error_2;
    var _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                registrationNumber = action.payload ? action.payload.registrationNumber : "";
                registrationNumber = registrationNumber.toUpperCase();
                state = action.payload ? action.payload.state : "";
                apiUrl = "".concat(process.env.ENV_API_VEHICLE_REGO_SEARCH, "?registrationNumber=").concat(registrationNumber, "&state=").concat(state);
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({
                        loadingStatus: CONSTANTS.LOADING_STATUS.loading,
                        loadingCall: CONSTANTS.LOADING_CALL.vehicleRegoSearch,
                    }))];
            case 1:
                _d.sent();
                _d.label = 2;
            case 2:
                _d.trys.push([2, 15, , 18]);
                return [4 /*yield*/, (0, effects_1.call)(function () {
                        return FUNCTIONS.callApiUrl({
                            url: apiUrl,
                            method: "get",
                        });
                    })];
            case 3:
                result = _d.sent();
                if (!(((_b = result === null || result === void 0 ? void 0 : result.data) === null || _b === void 0 ? void 0 : _b.status) === "SUCCESS")) return [3 /*break*/, 10];
                return [4 /*yield*/, (0, effects_1.put)((0, vehicleSlice_1.VehicleRegoSearch)({ data: result === null || result === void 0 ? void 0 : result.data, regoNumber: registrationNumber }))];
            case 4:
                _d.sent();
                _a = (result === null || result === void 0 ? void 0 : result.data).vehicleSummary, vehicleSummary = _a === void 0 ? {} : _a;
                return [4 /*yield*/, (0, effects_1.put)((0, stepsSlice_1.UpdateBookingStepSubTitle)({
                        stepId: "vehicle",
                        subTitle: "".concat(vehicleSummary && registrationNumber ? registrationNumber + " - " : registrationNumber).concat(vehicleSummary ? vehicleSummary === null || vehicleSummary === void 0 ? void 0 : vehicleSummary.make : "").concat(vehicleSummary ? ", " + (vehicleSummary === null || vehicleSummary === void 0 ? void 0 : vehicleSummary.model) : "").concat(vehicleSummary ? ", " + (vehicleSummary === null || vehicleSummary === void 0 ? void 0 : vehicleSummary.year) : "").concat(vehicleSummary ? ", " + (vehicleSummary === null || vehicleSummary === void 0 ? void 0 : vehicleSummary.variants) : "")
                    }))];
            case 5:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.delay)(CONSTANTS._loadBlockerDelay)];
            case 6:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.loaded }))];
            case 7:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.call)(VehicleContinueSaga, (_c = result === null || result === void 0 ? void 0 : result.data) === null || _c === void 0 ? void 0 : _c.vehicleId)];
            case 8:
                _d.sent(); //CALL NEXT SAGA
                return [4 /*yield*/, (0, effects_1.call)(stepsSaga_1.incrementChildStepSaga)];
            case 9:
                _d.sent();
                return [3 /*break*/, 14];
            case 10: return [4 /*yield*/, (0, effects_1.put)((0, vehicleSlice_1.VehicleRegoSearch)({ data: result === null || result === void 0 ? void 0 : result.data, regoNumber: registrationNumber }))];
            case 11:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.delay)(CONSTANTS._loadBlockerDelay)];
            case 12:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.error }))];
            case 13:
                _d.sent();
                _d.label = 14;
            case 14: return [3 /*break*/, 18];
            case 15:
                error_2 = _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, vehicleSlice_1.VehicleRegoSearch)([]))];
            case 16:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.error }))];
            case 17:
                _d.sent();
                return [3 /*break*/, 18];
            case 18: return [2 /*return*/];
        }
    });
}
exports.VehicleRegoSearchSaga = VehicleRegoSearchSaga;
//VEHICLE MAKES SEARCH
function VehicleMakesSearchSaga(action) {
    var make, apiUrl, result, error_3;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                make = encodeURI(action.payload ? action.payload : "");
                apiUrl = "".concat(process.env.ENV_API_VEHICLE_MAKE, "?make=").concat(make);
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({
                        loadingStatus: CONSTANTS.LOADING_STATUS.loadingAutoComplete,
                        loadingCall: CONSTANTS.LOADING_CALL.vehicleMakesSearch,
                    }))];
            case 1:
                _d.sent();
                _d.label = 2;
            case 2:
                _d.trys.push([2, 11, , 14]);
                return [4 /*yield*/, (0, effects_1.call)(function () {
                        return FUNCTIONS.callApiUrl({
                            url: apiUrl,
                            method: "get",
                        });
                    })];
            case 3:
                result = _d.sent();
                if (!(((_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.status) === "SUCCESS" && ((_b = result === null || result === void 0 ? void 0 : result.data) === null || _b === void 0 ? void 0 : _b.makes.length) > 0)) return [3 /*break*/, 7];
                return [4 /*yield*/, (0, effects_1.put)((0, vehicleSlice_1.VehicleMakesSearch)((_c = result === null || result === void 0 ? void 0 : result.data) === null || _c === void 0 ? void 0 : _c.makes))];
            case 4:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.delay)(CONSTANTS._loadBlockerDelay)];
            case 5:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.loaded }))];
            case 6:
                _d.sent();
                return [3 /*break*/, 10];
            case 7: return [4 /*yield*/, (0, effects_1.put)((0, vehicleSlice_1.VehicleMakesSearch)([]))];
            case 8:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.noResults }))];
            case 9:
                _d.sent();
                _d.label = 10;
            case 10: return [3 /*break*/, 14];
            case 11:
                error_3 = _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, vehicleSlice_1.VehicleMakesSearch)([]))];
            case 12:
                _d.sent();
                //yield put(FailureMessage(error.message));
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.error }))];
            case 13:
                //yield put(FailureMessage(error.message));
                _d.sent();
                return [3 /*break*/, 14];
            case 14: return [2 /*return*/];
        }
    });
}
exports.VehicleMakesSearchSaga = VehicleMakesSearchSaga;
//FETCH VEHICLE MODELS START
function VehicleModelsFetchStartSaga(action) {
    var make, apiUrl, result, error_4;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0: return [4 /*yield*/, (0, effects_1.select)(vehicleSlice_1.selectVehicleMake)];
            case 1:
                make = _d.sent();
                make = encodeURIComponent(make);
                apiUrl = "".concat(process.env.ENV_API_VEHICLE_MODEL, "?make=").concat(make, "&model=");
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({
                        loadingStatus: CONSTANTS.LOADING_STATUS.loading,
                        loadingCall: CONSTANTS.LOADING_CALL.vehicleModelsFetchStart,
                    }))];
            case 2:
                _d.sent();
                _d.label = 3;
            case 3:
                _d.trys.push([3, 12, , 16]);
                return [4 /*yield*/, (0, effects_1.call)(function () {
                        return FUNCTIONS.callApiUrl({
                            url: apiUrl,
                            method: "get",
                        });
                    })];
            case 4:
                result = _d.sent();
                if (!(((_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.status) === "SUCCESS" && ((_b = result === null || result === void 0 ? void 0 : result.data) === null || _b === void 0 ? void 0 : _b.models.length) > 0)) return [3 /*break*/, 8];
                return [4 /*yield*/, (0, effects_1.put)((0, vehicleSlice_1.VehicleModelsFetchStart)((_c = result === null || result === void 0 ? void 0 : result.data) === null || _c === void 0 ? void 0 : _c.models))];
            case 5:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.delay)(CONSTANTS._loadBlockerDelay)];
            case 6:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.loaded }))];
            case 7:
                _d.sent();
                return [3 /*break*/, 11];
            case 8: return [4 /*yield*/, (0, effects_1.put)((0, vehicleSlice_1.VehicleModelsFetchStart)([]))];
            case 9:
                _d.sent();
                //yield put(FailureMessage("No results found"));
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.noResults }))];
            case 10:
                //yield put(FailureMessage("No results found"));
                _d.sent();
                _d.label = 11;
            case 11: return [3 /*break*/, 16];
            case 12:
                error_4 = _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, vehicleSlice_1.VehicleModelsFetchStart)([]))];
            case 13:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.FailureMessage)(error_4.message))];
            case 14:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.error }))];
            case 15:
                _d.sent();
                return [3 /*break*/, 16];
            case 16: return [2 /*return*/];
        }
    });
}
exports.VehicleModelsFetchStartSaga = VehicleModelsFetchStartSaga;
//VEHICLE MODELS SEARCH
function VehicleModelsSearchSaga(action) {
    var make, apiUrl, result, error_5;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0: return [4 /*yield*/, (0, effects_1.select)(vehicleSlice_1.selectVehicleMake)];
            case 1:
                make = _d.sent();
                make = encodeURIComponent(make);
                apiUrl = "".concat(process.env.ENV_API_VEHICLE_MODEL, "?make=").concat(make, "&model=").concat(action.payload ? action.payload : "");
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({
                        loadingStatus: CONSTANTS.LOADING_STATUS.loadingAutoComplete,
                        loadingCall: CONSTANTS.LOADING_CALL.vehicleModelsSearch,
                    }))];
            case 2:
                _d.sent();
                _d.label = 3;
            case 3:
                _d.trys.push([3, 12, , 16]);
                return [4 /*yield*/, (0, effects_1.call)(function () {
                        return FUNCTIONS.callApiUrl({
                            url: apiUrl,
                            method: "get",
                        });
                    })];
            case 4:
                result = _d.sent();
                if (!(((_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.status) === "SUCCESS" && ((_b = result === null || result === void 0 ? void 0 : result.data) === null || _b === void 0 ? void 0 : _b.models.length) > 0)) return [3 /*break*/, 8];
                return [4 /*yield*/, (0, effects_1.put)((0, vehicleSlice_1.VehicleModelsSearch)((_c = result === null || result === void 0 ? void 0 : result.data) === null || _c === void 0 ? void 0 : _c.models))];
            case 5:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.delay)(CONSTANTS._loadBlockerDelay)];
            case 6:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.loaded }))];
            case 7:
                _d.sent();
                return [3 /*break*/, 11];
            case 8: return [4 /*yield*/, (0, effects_1.put)((0, vehicleSlice_1.VehicleModelsSearch)([]))];
            case 9:
                _d.sent();
                ///yield put(FailureMessage("No results found"));
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.noResults }))];
            case 10:
                ///yield put(FailureMessage("No results found"));
                _d.sent();
                _d.label = 11;
            case 11: return [3 /*break*/, 16];
            case 12:
                error_5 = _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, vehicleSlice_1.VehicleModelsSearch)([]))];
            case 13:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.FailureMessage)(error_5.message))];
            case 14:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.error }))];
            case 15:
                _d.sent();
                return [3 /*break*/, 16];
            case 16: return [2 /*return*/];
        }
    });
}
exports.VehicleModelsSearchSaga = VehicleModelsSearchSaga;
//VEHICLE YEAR GROUPINGS
function VehicleYearGroupingSaga(action) {
    var make, model, apiUrl, result, error_6;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0: return [4 /*yield*/, (0, effects_1.select)(vehicleSlice_1.selectVehicleMake)];
            case 1:
                make = _d.sent();
                return [4 /*yield*/, (0, effects_1.select)(vehicleSlice_1.selectVehicleModel)];
            case 2:
                model = _d.sent();
                model = encodeURIComponent(model);
                make = encodeURIComponent(make);
                apiUrl = "".concat(process.env.ENV_API_VEHICLE_YEAR, "?make=").concat(make, "&model=").concat(model, "&year=").concat(action.payload ? action.payload : "");
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({
                        loadingStatus: CONSTANTS.LOADING_STATUS.loading,
                        loadingCall: CONSTANTS.LOADING_CALL.vehicleYearGrouping,
                    }))];
            case 3:
                _d.sent();
                _d.label = 4;
            case 4:
                _d.trys.push([4, 14, , 18]);
                return [4 /*yield*/, (0, effects_1.call)(function () {
                        return FUNCTIONS.callApiUrl({
                            url: apiUrl,
                            method: "get",
                        });
                    })];
            case 5:
                result = _d.sent();
                if (!(((_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.status) === "SUCCESS" && ((_b = result === null || result === void 0 ? void 0 : result.data) === null || _b === void 0 ? void 0 : _b.yearGroupings.length) > 0)) return [3 /*break*/, 9];
                return [4 /*yield*/, (0, effects_1.put)((0, vehicleSlice_1.VehicleYearGroupings)((_c = result === null || result === void 0 ? void 0 : result.data) === null || _c === void 0 ? void 0 : _c.yearGroupings))];
            case 6:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.delay)(CONSTANTS._loadBlockerDelay)];
            case 7:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.loaded }))];
            case 8:
                _d.sent();
                return [3 /*break*/, 13];
            case 9: return [4 /*yield*/, (0, effects_1.put)((0, vehicleSlice_1.VehicleYearGroupings)([]))];
            case 10:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.FailureMessage)("No results found for vehicle year grouping"))];
            case 11:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.noResults }))];
            case 12:
                _d.sent();
                _d.label = 13;
            case 13: return [3 /*break*/, 18];
            case 14:
                error_6 = _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, vehicleSlice_1.VehicleYearGroupings)([]))];
            case 15:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.FailureMessage)(error_6.message))];
            case 16:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.error }))];
            case 17:
                _d.sent();
                return [3 /*break*/, 18];
            case 18: return [2 /*return*/];
        }
    });
}
exports.VehicleYearGroupingSaga = VehicleYearGroupingSaga;
//VEHICLE VARIANT
function VehicleVariantSaga(action) {
    var make, model, year, apiUrl, result, error_7;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0: return [4 /*yield*/, (0, effects_1.select)(vehicleSlice_1.selectVehicleMake)];
            case 1:
                make = _d.sent();
                return [4 /*yield*/, (0, effects_1.select)(vehicleSlice_1.selectVehicleModel)];
            case 2:
                model = _d.sent();
                model = encodeURIComponent(model);
                make = encodeURIComponent(make);
                return [4 /*yield*/, (0, effects_1.select)(vehicleSlice_1.selectVehicleYearGrouping)];
            case 3:
                year = _d.sent();
                apiUrl = "".concat(process.env.ENV_API_VEHICLE_VARIANT, "?make=").concat(make, "&model=").concat(model, "&year=").concat(year, "&variant=").concat(action.payload ? action.payload : "");
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({
                        loadingStatus: CONSTANTS.LOADING_STATUS.loading,
                        loadingCall: CONSTANTS.LOADING_CALL.vehicleVariant,
                    }))];
            case 4:
                _d.sent();
                _d.label = 5;
            case 5:
                _d.trys.push([5, 14, , 17]);
                return [4 /*yield*/, (0, effects_1.call)(function () {
                        return FUNCTIONS.callApiUrl({
                            url: apiUrl,
                            method: "get",
                        });
                    })];
            case 6:
                result = _d.sent();
                if (!(((_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.status) === "SUCCESS" && ((_b = result === null || result === void 0 ? void 0 : result.data) === null || _b === void 0 ? void 0 : _b.variants.length) > 0)) return [3 /*break*/, 10];
                return [4 /*yield*/, (0, effects_1.put)((0, vehicleSlice_1.VehicleVariants)((_c = result === null || result === void 0 ? void 0 : result.data) === null || _c === void 0 ? void 0 : _c.variants))];
            case 7:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.delay)(CONSTANTS._loadBlockerDelay)];
            case 8:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.loaded }))];
            case 9:
                _d.sent();
                return [3 /*break*/, 13];
            case 10: return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.FailureMessage)("No results found for vehicle variant"))];
            case 11:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.noResults }))];
            case 12:
                _d.sent();
                _d.label = 13;
            case 13: return [3 /*break*/, 17];
            case 14:
                error_7 = _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.FailureMessage)(error_7.message))];
            case 15:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.error }))];
            case 16:
                _d.sent();
                return [3 /*break*/, 17];
            case 17: return [2 /*return*/];
        }
    });
}
exports.VehicleVariantSaga = VehicleVariantSaga;
//VEHICLE CONTINUE
function VehicleContinueSaga(action) {
    var isEditBooking, currentChildStepId, vehicleId, apiUrl, result, error_8;
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    return __generator(this, function (_t) {
        switch (_t.label) {
            case 0:
                isEditBooking = action.payload && action.payload.isEditBooking;
                return [4 /*yield*/, (0, effects_1.select)(stepsSlice_1.selectCurrentChildStepId)];
            case 1:
                currentChildStepId = _t.sent();
                return [4 /*yield*/, (0, effects_1.select)(vehicleSlice_1.selectVehicleId)];
            case 2:
                vehicleId = _t.sent();
                apiUrl = "".concat(process.env.ENV_API_VEHICLE_CONTINUE, "?vehicleId=").concat(vehicleId);
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({
                        loadingStatus: CONSTANTS.LOADING_STATUS.loading,
                        loadingCall: CONSTANTS.LOADING_CALL.vehicleContinue,
                    }))];
            case 3:
                _t.sent();
                _t.label = 4;
            case 4:
                _t.trys.push([4, 17, , 20]);
                return [4 /*yield*/, (0, effects_1.call)(function () {
                        return FUNCTIONS.callApiUrl({
                            url: apiUrl,
                            method: "get",
                        });
                    })];
            case 5:
                result = _t.sent();
                if (!(((_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.status) === "SUCCESS" && ((_b = result === null || result === void 0 ? void 0 : result.data) === null || _b === void 0 ? void 0 : _b.oemFitment) === "NO" && ((_c = result === null || result === void 0 ? void 0 : result.data) === null || _c === void 0 ? void 0 : _c.hasBatteryForVehicle) === "YES" && ((_d = result === null || result === void 0 ? void 0 : result.data) === null || _d === void 0 ? void 0 : _d.workshopFitment) === "NO")) return [3 /*break*/, 10];
                return [4 /*yield*/, (0, effects_1.put)((0, vehicleSlice_1.VehicleContinue)({
                        oemFitment: (_e = result === null || result === void 0 ? void 0 : result.data) === null || _e === void 0 ? void 0 : _e.oemFitment,
                        hasBatteryForVehicle: (_f = result === null || result === void 0 ? void 0 : result.data) === null || _f === void 0 ? void 0 : _f.hasBatteryForVehicle,
                        workshopFitment: (_g = result === null || result === void 0 ? void 0 : result.data) === null || _g === void 0 ? void 0 : _g.workshopFitment,
                        registrationRequired: (_h = result === null || result === void 0 ? void 0 : result.data) === null || _h === void 0 ? void 0 : _h.registrationRequired,
                    }))];
            case 6:
                _t.sent();
                if (!(isEditBooking === null || isEditBooking === undefined || isEditBooking === "")) return [3 /*break*/, 8];
                if (!(currentChildStepId === types_1.EVehicleSearchMode.manual)) return [3 /*break*/, 8];
                return [4 /*yield*/, (0, effects_1.call)(stepsSaga_1.incrementStepSaga)];
            case 7:
                _t.sent();
                _t.label = 8;
            case 8: return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.loaded }))];
            case 9:
                _t.sent();
                _t.label = 10;
            case 10:
                if (!(((_j = result === null || result === void 0 ? void 0 : result.data) === null || _j === void 0 ? void 0 : _j.status) === "SUCCESS" && (((_k = result === null || result === void 0 ? void 0 : result.data) === null || _k === void 0 ? void 0 : _k.oemFitment) === "YES" || ((_l = result === null || result === void 0 ? void 0 : result.data) === null || _l === void 0 ? void 0 : _l.hasBatteryForVehicle) === "NO" || ((_m = result === null || result === void 0 ? void 0 : result.data) === null || _m === void 0 ? void 0 : _m.workshopFitment) === "YES"))) return [3 /*break*/, 13];
                return [4 /*yield*/, (0, effects_1.put)((0, vehicleSlice_1.VehicleContinue)({
                        oemFitment: (_o = result === null || result === void 0 ? void 0 : result.data) === null || _o === void 0 ? void 0 : _o.oemFitment,
                        hasBatteryForVehicle: (_p = result === null || result === void 0 ? void 0 : result.data) === null || _p === void 0 ? void 0 : _p.hasBatteryForVehicle,
                        workshopFitment: (_q = result === null || result === void 0 ? void 0 : result.data) === null || _q === void 0 ? void 0 : _q.workshopFitment,
                        registrationRequired: (_r = result === null || result === void 0 ? void 0 : result.data) === null || _r === void 0 ? void 0 : _r.registrationRequired,
                    }))];
            case 11:
                _t.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.loaded }))];
            case 12:
                _t.sent();
                _t.label = 13;
            case 13:
                if (!(((_s = result === null || result === void 0 ? void 0 : result.data) === null || _s === void 0 ? void 0 : _s.status) !== "SUCCESS")) return [3 /*break*/, 16];
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.FailureMessage)(CONSTANTS.GENERAL_ERROR_MESSAGE))];
            case 14:
                _t.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.error }))];
            case 15:
                _t.sent();
                _t.label = 16;
            case 16: return [3 /*break*/, 20];
            case 17:
                error_8 = _t.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.FailureMessage)(error_8.message))];
            case 18:
                _t.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.error }))];
            case 19:
                _t.sent();
                return [3 /*break*/, 20];
            case 20: return [2 /*return*/];
        }
    });
}
exports.VehicleContinueSaga = VehicleContinueSaga;
//ALL SAGAS
function vehicleSagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(ActionTypes_1.SagaActions.VEHICLE_MODELS_FETCH_START_SAGA, VehicleModelsFetchStartSaga)];
            case 1:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.takeLatest)(ActionTypes_1.SagaActions.VEHICLE_REGO_SEARCH_SAGA, VehicleRegoSearchSaga)];
            case 2:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.takeLatest)(ActionTypes_1.SagaActions.VEHICLE_MAKES_FETCH_START_SAGA, VehicleMakesFetchStartSaga)];
            case 3:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.takeLatest)(ActionTypes_1.SagaActions.VEHICLE_MAKES_SEARCH_SAGA, VehicleMakesSearchSaga)];
            case 4:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.takeLatest)(ActionTypes_1.SagaActions.VEHICLE_MODELS_SEARCH_SAGA, VehicleModelsSearchSaga)];
            case 5:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.takeLatest)(ActionTypes_1.SagaActions.VEHICLE_YEAR_GROUPING_SAGA, VehicleYearGroupingSaga)];
            case 6:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.takeLatest)(ActionTypes_1.SagaActions.VEHICLE_VARIANT_SAGA, VehicleVariantSaga)];
            case 7:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.takeLatest)(ActionTypes_1.SagaActions.VEHICLE_CONTINUE_SAGA, VehicleContinueSaga)];
            case 8:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.default = vehicleSagas;
