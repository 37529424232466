"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
//React + Pkgs
var react_1 = require("react");
var react_storage_complete_1 = require("react-storage-complete");
//Components
// import Heading from "../../components/Common/Heading";
var Accordion_1 = require("../../components/Common/Accordion");
var constants_1 = require("../../utils/constants");
var $accordionManagedText = constants_1.WINDOW_MESSAGES === null || constants_1.WINDOW_MESSAGES === void 0 ? void 0 : constants_1.WINDOW_MESSAGES.Accordions;
var HowToGetBatteryContainer = function () {
    var _a = (0, react_storage_complete_1.useSessionStorage)('revisitHowToGetBattery', { howtogetbattery: false }), revisit = _a[0], setRevisit = _a[1];
    var pushDataLayer = function () {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: "form-navigate",
            currentPage: "booking-howtogetbattery d",
            revisit: revisit.howtogetbattery
        });
    };
    (0, react_1.useEffect)(function () {
        pushDataLayer();
        if (revisit.howtogetbattery === false)
            setRevisit({ howtogetbattery: true });
        // eslint-disable-next-line
    }, []);
    return ((0, jsx_runtime_1.jsx)("div", { className: "HowToGetBatteryContainer container", children: (0, jsx_runtime_1.jsx)("div", { className: "AccordionItems", children: (0, jsx_runtime_1.jsx)("div", { className: "grid-container", children: (0, jsx_runtime_1.jsx)("div", { className: "grid-x grid-margin-x align-center", children: (0, jsx_runtime_1.jsx)("div", { className: "cell small-12 medium-10", children: (0, jsx_runtime_1.jsx)(Accordion_1.AccordionGroup, { items: $accordionManagedText === null || $accordionManagedText === void 0 ? void 0 : $accordionManagedText.HowToGetBattery }) }) }) }) }) }));
};
exports.default = HowToGetBatteryContainer;
