"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var BatteryIcons = [
    {
        label: "Installation",
        icon: "racq-installation",
    },
    {
        label: "Delivery",
        icon: "racq-time",
    },
    {
        label: "Battery Recycling",
        icon: "racq-battery",
    },
];
exports.default = BatteryIcons;
