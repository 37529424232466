"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClearLoadingState = exports.FailureMessage = exports.LoadingStatus = exports.selectFailureMessage = exports.selectLoadingCall = exports.selectLoadingStatus = exports.globalSlice = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var initialState = {
    loadingStatus: "",
    loadingCall: "",
    loadingFailureMessage: "",
};
exports.globalSlice = (0, toolkit_1.createSlice)({
    name: "global",
    initialState: initialState,
    reducers: {
        LoadingStatus: function (state, action) {
            var payload = action.payload;
            return __assign(__assign({}, state), { loadingStatus: payload.loadingStatus, loadingCall: payload.loadingCall ? payload.loadingCall : state.loadingCall });
        },
        FailureMessage: function (state, action) {
            var payload = action.payload;
            return __assign(__assign({}, state), { loadingFailureMessage: payload ? payload : "" });
        },
        ClearLoadingState: function (state, action) {
            return __assign(__assign({}, state), { loadingStatus: "", loadingCall: "", loadingFailureMessage: "" });
        },
    },
});
//Selectors
var selectLoadingStatus = function (state) { return state.global.loadingStatus; };
exports.selectLoadingStatus = selectLoadingStatus;
var selectLoadingCall = function (state) { return state.global.loadingCall; };
exports.selectLoadingCall = selectLoadingCall;
var selectFailureMessage = function (state) { return state.global.loadingFailureMessage; };
exports.selectFailureMessage = selectFailureMessage;
//Actions
exports.LoadingStatus = (_a = exports.globalSlice.actions, _a.LoadingStatus), exports.FailureMessage = _a.FailureMessage, exports.ClearLoadingState = _a.ClearLoadingState;
exports.default = exports.globalSlice.reducer;
