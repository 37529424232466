"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EVehicleSearchManualLabel = exports.EVehicleSearchMode = exports.BookingStatusOptions = exports.EBookingStatus = void 0;
var EBookingStatus;
(function (EBookingStatus) {
    EBookingStatus["All"] = "All";
    EBookingStatus["Pending"] = "Pending";
    EBookingStatus["Booked"] = "Booked";
    EBookingStatus["Past"] = "Past";
    EBookingStatus["Cancelled"] = "Cancelled";
})(EBookingStatus || (exports.EBookingStatus = EBookingStatus = {}));
exports.BookingStatusOptions = Object.values(EBookingStatus).map(function (status) { return ({
    value: status,
    label: status,
}); });
var EVehicleSearchMode;
(function (EVehicleSearchMode) {
    EVehicleSearchMode["rego"] = "rego";
    EVehicleSearchMode["manual"] = "manual";
})(EVehicleSearchMode || (exports.EVehicleSearchMode = EVehicleSearchMode = {}));
var EVehicleSearchManualLabel;
(function (EVehicleSearchManualLabel) {
    EVehicleSearchManualLabel["make"] = "make";
    EVehicleSearchManualLabel["model"] = "model";
    EVehicleSearchManualLabel["yearGrouping"] = "yearGrouping";
    EVehicleSearchManualLabel["variant"] = "variant";
})(EVehicleSearchManualLabel || (exports.EVehicleSearchManualLabel = EVehicleSearchManualLabel = {}));
