"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppointmentConfirmSaga = exports.AppointmentFetchDateTimeSaga = void 0;
//React + Pkgs
var effects_1 = require("@redux-saga/core/effects");
//Redux
var globalSlice_1 = require("../slices/globalSlice");
var appointmentSlice_1 = require("../slices/appointmentSlice");
var ActionTypes_1 = require("./ActionTypes");
//Funcs
var FUNCTIONS = __importStar(require("../../utils"));
var CONSTANTS = __importStar(require("../../utils/constants"));
//Environment Brand
var constants_1 = require("../../utils/constants");
var types_1 = require("../../brand-settings/racwa/types");
var $settingsManagedText = constants_1.WINDOW_MESSAGES === null || constants_1.WINDOW_MESSAGES === void 0 ? void 0 : constants_1.WINDOW_MESSAGES.Settings;
var $checkboxManagedText = constants_1.WINDOW_MESSAGES === null || constants_1.WINDOW_MESSAGES === void 0 ? void 0 : constants_1.WINDOW_MESSAGES.Checkboxes;
function AppointmentFetchDateTimeSaga(action) {
    var apiUrl, result, error_1;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                apiUrl = "".concat(process.env.ENV_API_APPOINTMENT_DATE_TIME, "?date=").concat(action.payload);
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({
                        loadingStatus: CONSTANTS.LOADING_STATUS.loading,
                        loadingCall: CONSTANTS.LOADING_CALL.appointmentFetchDateTime,
                    }))];
            case 1:
                _d.sent();
                _d.label = 2;
            case 2:
                _d.trys.push([2, 12, , 15]);
                return [4 /*yield*/, (0, effects_1.call)(function () {
                        return FUNCTIONS.callApiUrl({
                            url: apiUrl,
                            method: "get",
                        });
                    })];
            case 3:
                result = _d.sent();
                if (!(((_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.status) === "SUCCESS" && ((_b = result === null || result === void 0 ? void 0 : result.data) === null || _b === void 0 ? void 0 : _b.BookingSlots.length) > 0)) return [3 /*break*/, 7];
                return [4 /*yield*/, (0, effects_1.put)((0, appointmentSlice_1.AppointmentFetchDateTime)({
                        bookingSlots: (_c = result === null || result === void 0 ? void 0 : result.data) === null || _c === void 0 ? void 0 : _c.BookingSlots,
                        bookingDate: action.payload,
                    }))];
            case 4:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.delay)(CONSTANTS._loadBlockerDelay)];
            case 5:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({ loadingStatus: CONSTANTS.LOADING_STATUS.loaded }))];
            case 6:
                _d.sent();
                return [3 /*break*/, 11];
            case 7: return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.FailureMessage)(CONSTANTS.GENERAL_ERROR_MESSAGE))];
            case 8:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, appointmentSlice_1.AppointmentFetchDateTime)({
                        bookingSlots: [],
                        bookingDate: "",
                    }))];
            case 9:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({
                        loadingStatus: CONSTANTS.LOADING_STATUS.error,
                    }))];
            case 10:
                _d.sent();
                _d.label = 11;
            case 11: return [3 /*break*/, 15];
            case 12:
                error_1 = _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.FailureMessage)(error_1.message))];
            case 13:
                _d.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({
                        loadingStatus: CONSTANTS.LOADING_STATUS.error,
                    }))];
            case 14:
                _d.sent();
                return [3 /*break*/, 15];
            case 15: return [2 /*return*/];
        }
    });
}
exports.AppointmentFetchDateTimeSaga = AppointmentFetchDateTimeSaga;
function AppointmentConfirmSaga(action) {
    var apiUrl, _a, registrationNumber, colour, bookingDate, purchaserFirstName, purchaserLastName, purchaserEmail, purchaserPhoneNumber, sameAsPurchaser, attendeeFirstName, attendeeLastName, attendeePhoneNumber, additionalNotes, paymentOption1Checkbox, paymentOption2Checkbox, quarantineCheckbox, getAllState, bookingSlotID, _b, batteryPartNumber, memberId, bookingTypeId, isMembershipValid, _c, stockNumber, warranty, batteryType, ccAmps, priceVariants, basePrice, memberPrice, vehicleId, _d, fullAddress, streetAddress, suburb, state, postcode, unitType, unit, streetNumber, street1, street2, requestBody, result, finalPrice, error_2;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                apiUrl = "".concat(process.env.ENV_API_APPOINTMENT_CONFIRM);
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({
                        loadingStatus: CONSTANTS.LOADING_STATUS.loading,
                        loadingCall: CONSTANTS.LOADING_CALL.appointmentConfirm,
                    }))];
            case 1:
                _e.sent();
                _a = action.payload, registrationNumber = _a.registrationNumber, colour = _a.colour, bookingDate = _a.bookingDate, purchaserFirstName = _a.purchaserFirstName, purchaserLastName = _a.purchaserLastName, purchaserEmail = _a.purchaserEmail, purchaserPhoneNumber = _a.purchaserPhoneNumber, sameAsPurchaser = _a.sameAsPurchaser, attendeeFirstName = _a.attendeeFirstName, attendeeLastName = _a.attendeeLastName, attendeePhoneNumber = _a.attendeePhoneNumber, additionalNotes = _a.additionalNotes, paymentOption1Checkbox = _a.paymentOption1Checkbox, paymentOption2Checkbox = _a.paymentOption2Checkbox, quarantineCheckbox = _a.quarantineCheckbox;
                return [4 /*yield*/, (0, effects_1.select)()];
            case 2:
                getAllState = _e.sent();
                bookingSlotID = getAllState.appointment.bookingSlot.bookingSlotID;
                _b = getAllState.battery, batteryPartNumber = _b.batteryPartNumber, memberId = _b.memberId, bookingTypeId = _b.bookingTypeId, isMembershipValid = _b.isMembershipValid;
                _c = getAllState.battery.batteryObj, stockNumber = _c.stockNumber, warranty = _c.warranty, batteryType = _c.batteryType, ccAmps = _c.ccAmps, priceVariants = _c.priceVariants, basePrice = _c.basePrice, memberPrice = _c.memberPrice;
                vehicleId = getAllState.vehicle.vehicleId;
                _d = getAllState.location.addressObj, fullAddress = _d.fullAddress, streetAddress = _d.streetAddress, suburb = _d.suburb, state = _d.state, postcode = _d.postcode, unitType = _d.unitType, unit = _d.unit, streetNumber = _d.streetNumber, street1 = _d.street1, street2 = _d.street2;
                requestBody = {
                    bookingDate: FUNCTIONS.formatDateTime(new Date(bookingDate), "YYYY-MM-DD"),
                    bookingTypeId: bookingTypeId ? bookingTypeId : "",
                    bookingSlot: {
                        bookingSlotID: bookingSlotID,
                    },
                    batteryPartNumber: batteryPartNumber,
                    battery: {
                        stockNumber: stockNumber,
                        warranty: warranty,
                    },
                    purchaserInformation: {
                        firstName: purchaserFirstName,
                        lastName: purchaserLastName,
                        email: purchaserEmail,
                        phoneNumber: purchaserPhoneNumber,
                        memberId: memberId,
                    },
                    attendeeInformation: {
                        firstName: sameAsPurchaser ? purchaserFirstName : attendeeFirstName,
                        lastName: sameAsPurchaser ? purchaserLastName : attendeeLastName,
                        phoneNumber: sameAsPurchaser ? purchaserPhoneNumber : attendeePhoneNumber,
                        sameAsPurchaser: sameAsPurchaser,
                    },
                    location: {
                        fullAddress: fullAddress,
                        streetAddress: streetAddress ? streetAddress : "",
                        suburb: suburb,
                        state: state,
                        postcode: postcode,
                        unitType: unitType,
                        unit: unit,
                        streetNumber: streetNumber,
                        street1: street1,
                        street2: street2,
                        additionalNotes: additionalNotes,
                        externalLocationID: suburb, //TODO: Update/Remove this value?
                    },
                    vehicleInformation: {
                        vehicleId: vehicleId,
                        registrationNumber: registrationNumber,
                        colour: colour,
                    },
                    optIn: {
                        checkBox1Visible: memberId ? "NO" : $checkboxManagedText.Checkbox1Visible,
                        checkBox1Label: $checkboxManagedText.Checkbox1ManageBookingsLabel,
                        checkBox1Value: paymentOption1Checkbox ? "YES" : "NO",
                        checkBox2Visible: $checkboxManagedText.Checkbox2Visible,
                        checkBox2Label: $checkboxManagedText.Checkbox2ManageBookingsLabel,
                        checkBox2Value: paymentOption2Checkbox ? "YES" : "NO",
                    },
                    quarantine: {
                        quarantineVisible: $checkboxManagedText.QuarantineCheckboxVisible,
                        quarantineLabel: $checkboxManagedText.QuarantineCheckboxManageBookingsLabel,
                        quarantineValue: quarantineCheckbox ? "YES" : "NO",
                    },
                };
                console.log("requestBody appointment confirm", requestBody);
                _e.label = 3;
            case 3:
                _e.trys.push([3, 16, , 19]);
                return [4 /*yield*/, (0, effects_1.call)(function () {
                        return FUNCTIONS.callApiUrl({
                            url: apiUrl,
                            headers: {
                                "Content-Type": "application/json",
                            },
                            method: "POST",
                            data: requestBody,
                        });
                    })];
            case 4:
                result = _e.sent();
                if (!(result.data.status === "SUCCESS" && result.data.bookingReferenceNumber)) return [3 /*break*/, 11];
                finalPrice = isMembershipValid === "YES" ? memberPrice : basePrice;
                if (priceVariants) {
                    if (bookingTypeId === types_1.EBookingTypeId.deliveryOnly) {
                        finalPrice = isMembershipValid === "YES" ? priceVariants === null || priceVariants === void 0 ? void 0 : priceVariants.doMemberPrice : priceVariants === null || priceVariants === void 0 ? void 0 : priceVariants.doBasePrice;
                    }
                    else {
                        // delivery & install (default)
                        finalPrice = isMembershipValid === "YES" ? priceVariants === null || priceVariants === void 0 ? void 0 : priceVariants.diMemberPrice : priceVariants === null || priceVariants === void 0 ? void 0 : priceVariants.diBasePrice;
                    }
                }
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: "user_email_revenue",
                    user_email: purchaserEmail,
                    revenue: finalPrice,
                });
                window.dataLayer.push({
                    event: "BatteryOrderSubmitEvent",
                    formName: "Battery Quote Form",
                    eventCategory: "Battery Quote Form Form Submit",
                    eventAction: "Submit successful",
                    order: {
                        isMember: isMembershipValid === "YES",
                        isStaff: false,
                        InstallTotal: finalPrice,
                        InstallQty: 1,
                        DropoffTotal: 0,
                        DropoffQty: 0,
                    },
                    battery_type: batteryType,
                    option_chosen: bookingTypeId === types_1.EBookingTypeId.deliveryOnly ? "Delivery Only" : "Delivery + Install",
                    cca_rating: ccAmps,
                    part: stockNumber,
                    member_selection: isMembershipValid === "YES" ? "Member" : "Non-Member",
                });
                return [4 /*yield*/, (0, effects_1.put)((0, appointmentSlice_1.AppointmentConfirmSuccess)({
                        registrationNumber: registrationNumber,
                        colour: colour,
                        purchaserFirstName: purchaserFirstName,
                        purchaserLastName: purchaserLastName,
                        purchaserEmail: purchaserEmail,
                        purchaserPhoneNumber: purchaserPhoneNumber,
                        attendeeFirstName: attendeeFirstName,
                        attendeeLastName: attendeeLastName,
                        attendeePhoneNumber: attendeePhoneNumber,
                        memberId: memberId,
                        bookingRefNumber: result.data.bookingReferenceNumber,
                    }))];
            case 5:
                _e.sent();
                //yield put(LoadingStatus({ loadingStatus: CONSTANTS.LOADING_STATUS.loaded }));
                return [4 /*yield*/, (0, effects_1.delay)(CONSTANTS._loadBlockerDelay)];
            case 6:
                //yield put(LoadingStatus({ loadingStatus: CONSTANTS.LOADING_STATUS.loaded }));
                _e.sent();
                if (!window.location.href.includes("localhost")) return [3 /*break*/, 8];
                return [4 /*yield*/, (window.location.href = "".concat($settingsManagedText.SuccessUrl, ".html?booking=").concat(result.data.bookingReferenceNumber, "&token=").concat(result.data.token))];
            case 7:
                _e.sent(), "_blank";
                return [3 /*break*/, 10];
            case 8: return [4 /*yield*/, (window.location.href = "".concat($settingsManagedText.SuccessUrl, "?booking=").concat(result.data.bookingReferenceNumber, "&token=").concat(result.data.token))];
            case 9:
                _e.sent(), "_blank";
                _e.label = 10;
            case 10: return [3 /*break*/, 15];
            case 11:
                if (!result.data.messages) return [3 /*break*/, 13];
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.FailureMessage)(result.data.messages[0]))];
            case 12:
                _e.sent();
                _e.label = 13;
            case 13: return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({
                    loadingStatus: CONSTANTS.LOADING_STATUS.error,
                }))];
            case 14:
                _e.sent();
                _e.label = 15;
            case 15: return [3 /*break*/, 19];
            case 16:
                error_2 = _e.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.FailureMessage)(error_2.message))];
            case 17:
                _e.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({
                        loadingStatus: CONSTANTS.LOADING_STATUS.error,
                    }))];
            case 18:
                _e.sent();
                return [3 /*break*/, 19];
            case 19: return [2 /*return*/];
        }
    });
}
exports.AppointmentConfirmSaga = AppointmentConfirmSaga;
//ALL SAGAS
function appointmentSagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(ActionTypes_1.SagaActions.APPOINTMENT_FETCH_DATE_TIME_SAGA, AppointmentFetchDateTimeSaga)];
            case 1:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.takeLatest)(ActionTypes_1.SagaActions.APPOINTMENT_CONFIRM_SAGA, AppointmentConfirmSaga)];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.default = appointmentSagas;
