"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SagaActions = void 0;
exports.SagaActions = {
    INCREMENT_STEP_REQUEST: "INCREMENT_STEP_REQUEST",
    INCREMENT_CHILD_STEP_REQUEST: "INCREMENT_CHILD_STEP_REQUEST",
    GOTO_STEP_REQUEST: "GOTO_STEP_REQUEST",
    RESET_STEP_REQUEST: "RESET_STEP_REQUEST",
    UPDATE_SUBTITLE: "UPDATE_SUBTITLE",
    ADDRESS_FIND_SAGA: "ADDRESS_FIND_SAGA",
    ADDRESS_RETRIEVE_SAGA: "ADDRESS_RETRIEVE_SAGA",
    ADDRESS_CONTINUE_SAGA: "ADDRESS_CONTINUE_SAGA",
    ADDRESS_REVERSE_GEOCODE_SAGA: "ADDRESS_REVERSE_GEOCODE_SAGA",
    VEHICLE_MAKES_FETCH_START_SAGA: "VEHICLE_MAKE_FETCH_START_SAGA",
    VEHICLE_REGO_SEARCH_SAGA: "VEHICLE_REGO_SEARCH_SAGA",
    VEHICLE_MAKES_SEARCH_SAGA: "VEHICLE_MAKES_SEARCH_SAGA",
    VEHICLE_MODELS_FETCH_START_SAGA: "VEHICLE_MODELS_FETCH_START_SAGA",
    VEHICLE_MODELS_SEARCH_SAGA: "VEHICLE_MODELS_SEARCH_SAGA",
    VEHICLE_YEAR_GROUPING_SAGA: "VEHICLE_YEAR_GROUPING_SAGA",
    VEHICLE_VARIANT_SAGA: "VEHICLE_VARIANT_SAGA",
    VEHICLE_SEARCH_NO_RESULTS: "VEHICLE_SEARCH_NO_RESULTS",
    VEHICLE_CONTINUE_SAGA: "VEHICLE_CONTINUE_SAGA",
    BATTERY_FETCH_START_SAGA: "BATTERY_FETCH_START_SAGA",
    BATTERY_FETCH_BY_STOCK_NUMBER_SAGA: "BATTERY_FETCH_BY_STOCK_NUMBER_SAGA",
    BATTERY_MEMBERSHIP_SAGA: "BATTERY_MEMBERSHIP_SAGA",
    BATTERY_CLEAR_MEMBERSHIP_SAGA: "BATTERY_CLEAR_MEMBERSHIP_SAGA",
    BATTERY_CONTINUE_SAGA: "BATTERY_CONTINUE_SAGA",
    BATTERY_COLLECT_PART_NUMBERS: "BATTERT_COLLECT_PART_NUMBERS",
    BATTERY_UPDATE_BOOKING_TYPE: "BATTERY_UPDATE_BOOKING_TYPE",
    APPOINTMENT_FETCH_DATE_TIME_SAGA: "APPOINTMENT_FETCH_DATE_TIME_SAGA",
    APPOINTMENT_CONFIRM_SAGA: "APPOINTMENT_CONFIRM_SAGA",
    GET_BOOKING_SAGA: "GET_BOOKING_SAGA",
    UPDATE_BOOKING_SAGA: "UPDATE_BOOKING_SAGA",
    CANCEL_BOOKING_SAGA: "CANCEL_BOOKING_SAGA",
};
