"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResetCurrentStep = exports.UpdateBookingStepSubTitle = exports.UpdateCurrentChildStepId = exports.UpdateCurrentStep = exports.selectIsAppointmentValid = exports.selectIsBatteryValid = exports.selectIsVehicleValid = exports.selectIsLocationValid = exports.selectCurrentChildStepId = exports.selectCurrentChildStep = exports.selectCurrentStep = exports.selectLoadingStatus = exports.selectBookingSteps = exports.selectStepsState = exports.selectEntireState = exports.stepsSlice = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var steps_root_1 = __importStar(require("../../brand-settings/_rootConfig/steps-root"));
var initialState = __assign({}, steps_root_1.default);
exports.stepsSlice = (0, toolkit_1.createSlice)({
    name: "steps",
    initialState: initialState,
    reducers: {
        UpdateCurrentStep: function (state, action) {
            var _a = action.payload, value = _a.value, currentStep = _a.currentStep, currentChildStep = _a.currentChildStep, currentChildStepId = _a.currentChildStepId, isComplete = _a.isComplete, isChildComplete = _a.isChildComplete;
            var parentStep = state.bookingSteps.find(function (step) { return step.stepNumber === state.currentStep; });
            if (parentStep) {
                if (value !== undefined) {
                    parentStep.value = value; // update parent step value
                }
                if (isComplete !== undefined) {
                    parentStep.isComplete = isComplete; // update parent step isComplete
                }
                if (parentStep.childSteps && currentChildStep !== undefined && currentChildStepId !== undefined) {
                    var childStep = parentStep.childSteps.find(function (child) { return child.id === currentChildStepId; });
                    if (childStep) {
                        var childStepVariantId = childStep.steps.find(function (step) { return step.stepNumber === currentChildStep; });
                        if (childStepVariantId) {
                            if (childStepVariantId.stepNumber < childStep.steps.length) {
                                parentStep.currentChildStep += 1;
                            }
                            if (isChildComplete !== undefined) {
                                childStepVariantId.isComplete = isChildComplete;
                            }
                        }
                    }
                }
            }
            state.currentStep = currentStep;
        },
        UpdateCurrentChildStepId: function (state, action) {
            var newChildStepId = action.payload.newChildStepId;
            var currentStep = state.currentStep;
            var step = state.bookingSteps.find(function (step) { return step.stepNumber === currentStep; });
            if (step === null || step === void 0 ? void 0 : step.childSteps) {
                var childStep = step.childSteps.find(function (child) { return child.id === newChildStepId; });
                if (childStep) {
                    step.currentChildStepId = newChildStepId;
                }
            }
        },
        UpdateBookingStepSubTitle: function (state, action) {
            var _a = action.payload, stepId = _a.stepId, subTitle = _a.subTitle;
            var step = state.bookingSteps.find(function (step) { return step.id === stepId; });
            if (step) {
                step.subTitle = subTitle;
            }
        },
        ResetCurrentStep: function (state, action) {
            var _a = action.payload, targetStep = _a.targetStep, currentChildStep = _a.currentChildStep, value = _a.value, subTitle = _a.subTitle, isComplete = _a.isComplete, resetChildSteps = _a.resetChildSteps, currentChildStepId = _a.currentChildStepId;
            var step = state.bookingSteps.find(function (step) { return step.stepNumber === targetStep; });
            if (step) {
                step.value = value;
                step.isComplete = isComplete;
                step.currentChildStep = currentChildStep;
                step.subTitle = subTitle;
                // Find the child step and set its isComplete to false
                if (resetChildSteps !== undefined && step.childSteps) {
                    resetChildSteps.forEach(function (childStepNumber) {
                        var childStep = step.childSteps.find(function (child) {
                            return child.id === currentChildStepId &&
                                child.steps.some(function (step) { return step.stepNumber === childStepNumber; });
                        });
                        if (childStep) {
                            var stepToModify = childStep.steps.find(function (step) { return step.stepNumber === childStepNumber; });
                            if (stepToModify) {
                                stepToModify.isComplete = false;
                            }
                        }
                    });
                }
            }
        },
    },
});
//Selectors
var selectEntireState = function (state) { return state; };
exports.selectEntireState = selectEntireState;
var selectStepsState = function (state) { return state.steps; };
exports.selectStepsState = selectStepsState;
var selectBookingSteps = function (state) { return state.steps.bookingSteps; };
exports.selectBookingSteps = selectBookingSteps;
var selectLoadingStatus = function (state) { return state.steps.loadingStatus; };
exports.selectLoadingStatus = selectLoadingStatus;
var selectCurrentStep = function (state) { return state.steps.currentStep; };
exports.selectCurrentStep = selectCurrentStep;
var selectCurrentChildStep = function (state) { var _a; return (_a = state.steps.bookingSteps.find(function (step) { return step.stepNumber === state.steps.currentStep; })) === null || _a === void 0 ? void 0 : _a.currentChildStep; };
exports.selectCurrentChildStep = selectCurrentChildStep;
var selectCurrentChildStepId = function (state) { var _a; return (_a = state.steps.bookingSteps.find(function (step) { return step.stepNumber === state.steps.currentStep; })) === null || _a === void 0 ? void 0 : _a.currentChildStepId; };
exports.selectCurrentChildStepId = selectCurrentChildStepId;
exports.selectIsLocationValid = (0, toolkit_1.createSelector)(exports.selectEntireState, function (entireState) {
    if (steps_root_1.StepDependencies && typeof steps_root_1.StepDependencies['location'] === 'function') {
        var isValid = steps_root_1.StepDependencies['location'](entireState);
        return isValid !== undefined ? isValid : false;
    }
    return false;
});
exports.selectIsVehicleValid = (0, toolkit_1.createSelector)(exports.selectEntireState, function (entireState) {
    if (steps_root_1.StepDependencies && typeof steps_root_1.StepDependencies['vehicle'] === 'function') {
        var isValid = steps_root_1.StepDependencies['vehicle'](entireState);
        return isValid !== undefined ? isValid : false;
    }
    return false;
});
exports.selectIsBatteryValid = (0, toolkit_1.createSelector)(exports.selectEntireState, function (entireState) {
    if (steps_root_1.StepDependencies && typeof steps_root_1.StepDependencies['battery'] === 'function') {
        var isValid = steps_root_1.StepDependencies['battery'](entireState);
        return isValid !== undefined ? isValid : false;
    }
    return false;
});
exports.selectIsAppointmentValid = (0, toolkit_1.createSelector)(exports.selectEntireState, function (entireState) {
    if (steps_root_1.StepDependencies && typeof steps_root_1.StepDependencies['appointment'] === 'function') {
        var isValid = steps_root_1.StepDependencies['appointment'](entireState);
        return isValid !== undefined ? isValid : false;
    }
    return false;
});
//Actions
exports.UpdateCurrentStep = (_a = exports.stepsSlice.actions, _a.UpdateCurrentStep), exports.UpdateCurrentChildStepId = _a.UpdateCurrentChildStepId, exports.UpdateBookingStepSubTitle = _a.UpdateBookingStepSubTitle, exports.ResetCurrentStep = _a.ResetCurrentStep;
exports.default = exports.stepsSlice.reducer;
