"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppointmentReset = exports.AppointmentConfirmSuccess = exports.AppointmentSetTime = exports.AppointmentFetchDateTime = exports.selectAppointmentAttendeeInfo = exports.selectAppointmentPurchaserInfo = exports.selectAppointmentVehicleInfo = exports.selectAppointmentStatus = exports.selectAppointmentBookingSlot = exports.selectAppointmentBookingDate = exports.selectAppointmentTimeSlots = exports.appointmentSlice = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
//Store Data based on Appointment Screen
var initialState = {
    timeSlots: [],
    appointmentStatus: "",
    bookingDate: "",
    bookingSlot: {
        bookingTime: "",
        bookingSlotID: "",
        bookingStartTime: "",
        bookingEndTime: "",
        maximumAvailableBookings: null,
    },
    purchaserInformation: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        memberId: "",
    },
    attendeeInformation: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
    },
    vehicleInformation: {
        registrationNumber: "",
        colour: "",
    },
    promotionalCode: "",
};
exports.appointmentSlice = (0, toolkit_1.createSlice)({
    name: "appointment",
    initialState: initialState,
    reducers: {
        AppointmentFetchDateTime: function (state, action) {
            var _a = action.payload, bookingSlots = _a.bookingSlots, bookingDate = _a.bookingDate;
            var transformedBookingSlots = bookingSlots.map(function (item) { return ({
                label: item.TimeSlot,
                value: item.BookingSlotId,
                bookingStartTime: item.TimeSlotStart,
                bookingEndTime: item.TimeSlotEnd,
                maximumAvailableBookings: item.MaximumAvailability,
            }); });
            return __assign(__assign({}, state), { timeSlots: transformedBookingSlots, bookingDate: bookingDate });
        },
        AppointmentSetTime: function (state, action) {
            var payload = action.payload;
            var transformedObject = (0, toolkit_1.current)(state.timeSlots).filter(function (item) {
                if ((item === null || item === void 0 ? void 0 : item.value) === payload.bookingSlotID)
                    return item;
            });
            var startEndTimeObj = Object.assign.apply(Object, __spreadArray([{}], transformedObject, false));
            var bookingStartTime = new Date(startEndTimeObj.bookingStartTime).toLocaleTimeString();
            var bookingEndTime = new Date(startEndTimeObj.bookingEndTime).toLocaleTimeString();
            return __assign(__assign({}, state), { bookingSlot: {
                    bookingTime: payload.bookingTime ? payload.bookingTime : startEndTimeObj.bookingTime,
                    bookingSlotID: payload.bookingSlotID ? payload.bookingSlotID : startEndTimeObj.bookingSlotID,
                    bookingStartTime: bookingStartTime ? bookingStartTime : payload.bookingStartTime,
                    bookingEndTime: bookingEndTime ? bookingEndTime : payload.bookingEndTime,
                    maximumAvailableBookings: startEndTimeObj.maximumAvailableBookings ? startEndTimeObj.maximumAvailableBookings : payload.maximumAvailableBookings,
                } });
        },
        AppointmentConfirmSuccess: function (state, action) {
            var _a = action.payload, registrationNumber = _a.registrationNumber, colour = _a.colour, purchaserFirstName = _a.purchaserFirstName, purchaserLastName = _a.purchaserLastName, purchaserEmail = _a.purchaserEmail, purchaserPhoneNumber = _a.purchaserPhoneNumber, attendeeFirstName = _a.attendeeFirstName, attendeeLastName = _a.attendeeLastName, attendeePhoneNumber = _a.attendeePhoneNumber, memberId = _a.memberId;
            return __assign(__assign({}, state), { vehicleInformation: {
                    registrationNumber: registrationNumber,
                    colour: colour,
                }, purchaserInformation: {
                    firstName: purchaserFirstName,
                    lastName: purchaserLastName,
                    email: purchaserEmail,
                    phoneNumber: purchaserPhoneNumber,
                    memberId: memberId,
                }, attendeeInformation: {
                    firstName: attendeeFirstName,
                    lastName: attendeeLastName,
                    phoneNumber: attendeePhoneNumber,
                }, appointmentStatus: "Booking Confirmed" });
        },
        AppointmentReset: function (state, action) {
            return __assign({}, initialState);
        }
    },
});
//Selectors
var selectAppointmentTimeSlots = function (state) { return state.appointment.timeSlots; };
exports.selectAppointmentTimeSlots = selectAppointmentTimeSlots;
var selectAppointmentBookingDate = function (state) { return state.appointment.bookingDate; };
exports.selectAppointmentBookingDate = selectAppointmentBookingDate;
var selectAppointmentBookingSlot = function (state) { return state.appointment.bookingSlot; };
exports.selectAppointmentBookingSlot = selectAppointmentBookingSlot;
var selectAppointmentStatus = function (state) { return state.appointment.appointmentStatus; };
exports.selectAppointmentStatus = selectAppointmentStatus;
var selectAppointmentVehicleInfo = function (state) { return state.appointment.vehicleInformation; };
exports.selectAppointmentVehicleInfo = selectAppointmentVehicleInfo;
var selectAppointmentPurchaserInfo = function (state) { return state.appointment.purchaserInformation; };
exports.selectAppointmentPurchaserInfo = selectAppointmentPurchaserInfo;
var selectAppointmentAttendeeInfo = function (state) { return state.appointment.attendeeInformation; };
exports.selectAppointmentAttendeeInfo = selectAppointmentAttendeeInfo;
//Actions
exports.AppointmentFetchDateTime = (_a = exports.appointmentSlice.actions, _a.AppointmentFetchDateTime), exports.AppointmentSetTime = _a.AppointmentSetTime, exports.AppointmentConfirmSuccess = _a.AppointmentConfirmSuccess, exports.AppointmentReset = _a.AppointmentReset;
exports.default = exports.appointmentSlice.reducer;
