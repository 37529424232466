"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContainerComponents = void 0;
var containerLayoutImport_1 = require("./containerLayoutImport");
var constants_1 = require("@/utils/constants");
// List of container names
var containerNames = ["LocationContainer", "VehicleContainer", "BatteryContainer", "AppointmentContainer", "HowToGetBatteryContainer"];
/**
 * Retrieves the layout type for the AppointmentContainer based on the BrandConfig flag.
 * For AppointmentContainer, it returns "Secondary" if NewAppointmentTemplate is "YES", otherwise "Primary".
 * For all other containers, it returns undefined.
 * @param containerName - The name of the container.
 * @returns The layout type or undefined.
 */
var getLayoutType = function (containerName) {
    if (containerName === "AppointmentContainer") {
        return constants_1.WINDOW_MESSAGES.Settings.NewAppointmentTemplate === "YES" ? "Secondary" : "Primary";
    }
    return undefined; // No layout type needed for other containers
};
exports.ContainerComponents = containerNames.reduce(function (components, containerName) {
    var layoutType = getLayoutType(containerName);
    components[containerName] = (0, containerLayoutImport_1.ContainerImportLayout)(containerName, layoutType);
    return components;
}, {});
