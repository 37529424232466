"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetStepSaga = exports.goToStepSaga = exports.incrementChildStepSaga = exports.incrementStepSaga = exports.resetStepRequest = exports.goToStepRequest = exports.incrementChildStepRequest = exports.incrementStepRequest = void 0;
var effects_1 = require("redux-saga/effects");
var toolkit_1 = require("@reduxjs/toolkit");
var stepsSlice_1 = require("@/redux/slices/stepsSlice");
var steps_root_1 = __importStar(require("@/brand-settings/_root-config/steps-root"));
var functions_root_1 = require("@/brand-settings/_root-config/functions-root");
var ActionTypes_1 = require("@/redux/sagas/ActionTypes");
exports.incrementStepRequest = (0, toolkit_1.createAction)(ActionTypes_1.SagaActions.INCREMENT_STEP_REQUEST);
exports.incrementChildStepRequest = (0, toolkit_1.createAction)(ActionTypes_1.SagaActions.INCREMENT_CHILD_STEP_REQUEST);
exports.goToStepRequest = (0, toolkit_1.createAction)(ActionTypes_1.SagaActions.GOTO_STEP_REQUEST);
exports.resetStepRequest = (0, toolkit_1.createAction)(ActionTypes_1.SagaActions.RESET_STEP_REQUEST);
function incrementStepSaga() {
    var state, currentStep, parentId, isValid;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, (0, effects_1.select)()];
            case 1:
                state = _b.sent();
                currentStep = (0, stepsSlice_1.selectCurrentStep)(state);
                parentId = steps_root_1.default === null || steps_root_1.default === void 0 ? void 0 : steps_root_1.default.bookingSteps.find(function (step) { return step.stepNumber === currentStep; }).id;
                isValid = (0, functions_root_1.validateStepDependency)(parentId, state);
                if (!isValid) return [3 /*break*/, 3];
                if (!(currentStep < ((_a = steps_root_1.default === null || steps_root_1.default === void 0 ? void 0 : steps_root_1.default.bookingSteps) === null || _a === void 0 ? void 0 : _a.length))) return [3 /*break*/, 3];
                return [4 /*yield*/, (0, effects_1.put)({
                        type: stepsSlice_1.UpdateCurrentStep,
                        payload: {
                            currentStep: currentStep + 1,
                            value: 100,
                            isComplete: true,
                        },
                    })];
            case 2:
                _b.sent();
                _b.label = 3;
            case 3: return [2 /*return*/];
        }
    });
}
exports.incrementStepSaga = incrementStepSaga;
function incrementChildStepSaga() {
    var state, currentStep, currentChildStep, currentChildStepId;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.select)()];
            case 1:
                state = _a.sent();
                currentStep = (0, stepsSlice_1.selectCurrentStep)(state);
                currentChildStep = (0, stepsSlice_1.selectCurrentChildStep)(state);
                currentChildStepId = (0, stepsSlice_1.selectCurrentChildStepId)(state);
                return [4 /*yield*/, (0, effects_1.put)({
                        type: stepsSlice_1.UpdateCurrentStep,
                        payload: {
                            currentStep: currentStep,
                            currentChildStep: currentChildStep,
                            currentChildStepId: currentChildStepId,
                            isChildComplete: true,
                        },
                    })];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.incrementChildStepSaga = incrementChildStepSaga;
function goToStepSaga(_a) {
    var state, currentStep, parentId, stepNumber, isValid;
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var targetStepNumber = _a.targetStepNumber, parentStepId = _a.parentStepId;
    return __generator(this, function (_l) {
        switch (_l.label) {
            case 0: return [4 /*yield*/, (0, effects_1.select)()];
            case 1:
                state = _l.sent();
                currentStep = (0, stepsSlice_1.selectCurrentStep)(state);
                // If parentStepId is provided, use it to find the parentId
                if (parentStepId) {
                    parentId = (_c = (_b = steps_root_1.default === null || steps_root_1.default === void 0 ? void 0 : steps_root_1.default.bookingSteps) === null || _b === void 0 ? void 0 : _b.find(function (step) { return step.id === parentStepId; })) === null || _c === void 0 ? void 0 : _c.id;
                    stepNumber = (_e = (_d = steps_root_1.default === null || steps_root_1.default === void 0 ? void 0 : steps_root_1.default.bookingSteps) === null || _d === void 0 ? void 0 : _d.find(function (step) { return step.id === parentId; })) === null || _e === void 0 ? void 0 : _e.stepNumber;
                }
                // If targetStep is provided, use it to find the parentId
                else if (targetStepNumber && targetStepNumber >= 1 && targetStepNumber <= ((_f = steps_root_1.default === null || steps_root_1.default === void 0 ? void 0 : steps_root_1.default.bookingSteps) === null || _f === void 0 ? void 0 : _f.length)) {
                    parentId = (_h = (_g = steps_root_1.default === null || steps_root_1.default === void 0 ? void 0 : steps_root_1.default.bookingSteps) === null || _g === void 0 ? void 0 : _g.find(function (step) { return step.stepNumber === targetStepNumber; })) === null || _h === void 0 ? void 0 : _h.id;
                    stepNumber = (_k = (_j = steps_root_1.default === null || steps_root_1.default === void 0 ? void 0 : steps_root_1.default.bookingSteps) === null || _j === void 0 ? void 0 : _j.find(function (step) { return step.id === parentId; })) === null || _k === void 0 ? void 0 : _k.stepNumber;
                }
                if (!parentId) return [3 /*break*/, 3];
                isValid = (0, functions_root_1.validateStepDependency)(parentId, state);
                if (!isValid) return [3 /*break*/, 3];
                return [4 /*yield*/, (0, effects_1.put)({
                        type: stepsSlice_1.UpdateCurrentStep,
                        payload: {
                            currentStep: stepNumber || currentStep,
                        },
                    })];
            case 2:
                _l.sent();
                _l.label = 3;
            case 3: return [2 /*return*/];
        }
    });
}
exports.goToStepSaga = goToStepSaga;
function resetStepSaga(_a) {
    var state, currentStep, currentChildStepId, step, resetActions, childSteps, matchingChildStep, _i, resetActions_1, actionOrPair, _b, actionOrPair_1, action;
    var childStepNumber = _a.childStepNumber;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, (0, effects_1.select)()];
            case 1:
                state = _c.sent();
                currentStep = (0, stepsSlice_1.selectCurrentStep)(state);
                currentChildStepId = (0, stepsSlice_1.selectCurrentChildStepId)(state);
                step = state.steps.bookingSteps.find(function (step) { return step.stepNumber === currentStep; });
                if (step) {
                    childSteps = step.childSteps;
                    matchingChildStep = childSteps === null || childSteps === void 0 ? void 0 : childSteps.find(function (childStep) { return childStep.id === currentChildStepId; });
                    if (matchingChildStep) {
                        resetActions = steps_root_1.StepResetDependencies[step.id][currentChildStepId][childStepNumber](state);
                    }
                    else {
                        resetActions = steps_root_1.StepResetDependencies[step.id]["default"][childStepNumber](state);
                    }
                }
                _i = 0, resetActions_1 = resetActions;
                _c.label = 2;
            case 2:
                if (!(_i < resetActions_1.length)) return [3 /*break*/, 10];
                actionOrPair = resetActions_1[_i];
                if (!Array.isArray(actionOrPair)) return [3 /*break*/, 7];
                _b = 0, actionOrPair_1 = actionOrPair;
                _c.label = 3;
            case 3:
                if (!(_b < actionOrPair_1.length)) return [3 /*break*/, 6];
                action = actionOrPair_1[_b];
                return [4 /*yield*/, (0, effects_1.put)(action)];
            case 4:
                _c.sent();
                _c.label = 5;
            case 5:
                _b++;
                return [3 /*break*/, 3];
            case 6: return [3 /*break*/, 9];
            case 7: 
            // If it's a single action, dispatch it directly
            return [4 /*yield*/, (0, effects_1.put)(actionOrPair)];
            case 8:
                // If it's a single action, dispatch it directly
                _c.sent();
                _c.label = 9;
            case 9:
                _i++;
                return [3 /*break*/, 2];
            case 10: return [2 /*return*/];
        }
    });
}
exports.resetStepSaga = resetStepSaga;
function stepsSagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(exports.incrementStepRequest.type, incrementStepSaga)];
            case 1:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.takeLatest)(exports.incrementChildStepRequest.type, incrementChildStepSaga)];
            case 2:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.takeLatest)(exports.goToStepRequest.type, function (action) {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, goToStepSaga(action.payload)];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    })];
            case 3:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.takeLatest)(exports.resetStepRequest.type, function (action) {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, resetStepSaga(action.payload)];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    })];
            case 4:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.default = stepsSagas;
