"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EVehicleSearchManualLabel = exports.EVehicleSearchMode = void 0;
var EVehicleSearchMode;
(function (EVehicleSearchMode) {
    EVehicleSearchMode["rego"] = "rego";
    EVehicleSearchMode["manual"] = "manual";
})(EVehicleSearchMode || (exports.EVehicleSearchMode = EVehicleSearchMode = {}));
var EVehicleSearchManualLabel;
(function (EVehicleSearchManualLabel) {
    EVehicleSearchManualLabel["make"] = "make";
    EVehicleSearchManualLabel["model"] = "model";
    EVehicleSearchManualLabel["yearGrouping"] = "yearGrouping";
    EVehicleSearchManualLabel["variant"] = "variant";
})(EVehicleSearchManualLabel || (exports.EVehicleSearchManualLabel = EVehicleSearchManualLabel = {}));
;
