"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
//Styles
require("./index.scss");
var ContentBlurb = function (_a) {
    var blurb = _a.blurb, cellSize = _a.cellSize, align = _a.align, classNames = _a.classNames, dataTestId = _a.dataTestId;
    return ((0, jsx_runtime_1.jsx)("div", { className: "ContentBlurb ".concat(classNames ? classNames : ""), children: (0, jsx_runtime_1.jsx)("div", { className: "grid-container", children: (0, jsx_runtime_1.jsx)("div", { className: "grid-x grid-margin-x align-".concat(align, " text-").concat(align), children: (0, jsx_runtime_1.jsx)("div", { className: "cell ".concat(cellSize), children: (0, jsx_runtime_1.jsx)("p", { dangerouslySetInnerHTML: { __html: blurb }, "data-testid": dataTestId }) }) }) }) }));
};
exports.default = ContentBlurb;
