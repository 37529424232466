"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CancelBookingSaga = exports.UpdateBookingSaga = exports.GetBookingSaga = void 0;
//React + Pkgs
var effects_1 = require("@redux-saga/core/effects");
//Redux
var globalSlice_1 = require("@/redux/slices/globalSlice");
// import { selectVehicleId } from "../slices/vehicleSlice";
var bookingSlice_1 = require("@/redux/slices/bookingSlice");
// import { AppointmentFetchDateTime } from "../slices/appointmentSlice";
var ActionTypes_1 = require("@/redux/sagas/ActionTypes");
//Funcs
var FUNCTIONS = __importStar(require("@/utils"));
var CONSTANTS = __importStar(require("@/utils/constants"));
var constants_1 = require("@/utils/constants");
var $settingsManagedText = constants_1.WINDOW_MESSAGES === null || constants_1.WINDOW_MESSAGES === void 0 ? void 0 : constants_1.WINDOW_MESSAGES.Settings;
var bookingReferenceNumber = FUNCTIONS.getQueryStringUrlParamValue("booking");
var token = FUNCTIONS.getQueryStringUrlParamValue("token");
//Used in EDIT Booking to manage state
function GetBookingSaga(action) {
    var apiUrl, result, error_1;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                apiUrl = "".concat(process.env.ENV_API_BOOKING_GET_BOOKING, "?booking=").concat(bookingReferenceNumber, "&token=").concat(token);
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({
                        loadingStatus: CONSTANTS.LOADING_STATUS.loading,
                        loadingCall: CONSTANTS.LOADING_CALL.getBooking,
                    }))];
            case 1:
                _c.sent();
                _c.label = 2;
            case 2:
                _c.trys.push([2, 11, , 14]);
                return [4 /*yield*/, (0, effects_1.call)(function () {
                        return FUNCTIONS.callApiUrl({
                            url: apiUrl,
                            method: "get",
                        });
                    })];
            case 3:
                result = _c.sent();
                if (!(((_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.status) === "SUCCESS")) return [3 /*break*/, 7];
                return [4 /*yield*/, (0, effects_1.put)((0, bookingSlice_1.FetchBookingSuccess)(result === null || result === void 0 ? void 0 : result.data))];
            case 4:
                _c.sent(); //Common Slice
                return [4 /*yield*/, (0, effects_1.delay)(CONSTANTS._loadBlockerDelay)];
            case 5:
                _c.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({
                        loadingStatus: CONSTANTS.LOADING_STATUS.loaded,
                    }))];
            case 6:
                _c.sent();
                _c.label = 7;
            case 7:
                if (!(((_b = result === null || result === void 0 ? void 0 : result.data) === null || _b === void 0 ? void 0 : _b.status) === "ERROR")) return [3 /*break*/, 10];
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.FailureMessage)(CONSTANTS.GENERAL_ERROR_MESSAGE))];
            case 8:
                _c.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({
                        loadingStatus: CONSTANTS.LOADING_STATUS.error,
                    }))];
            case 9:
                _c.sent();
                _c.label = 10;
            case 10: return [3 /*break*/, 14];
            case 11:
                error_1 = _c.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.FailureMessage)(error_1.message))];
            case 12:
                _c.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({
                        loadingStatus: CONSTANTS.LOADING_STATUS.error,
                    }))];
            case 13:
                _c.sent();
                return [3 /*break*/, 14];
            case 14: return [2 /*return*/];
        }
    });
}
exports.GetBookingSaga = GetBookingSaga;
//Edit Booking
function UpdateBookingSaga(action) {
    var apiUrl, requestBody, result, error_2;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                apiUrl = "".concat(process.env.ENV_API_BOOKING_UPDATE_BOOKING);
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({
                        loadingStatus: CONSTANTS.LOADING_STATUS.loading,
                        loadingCall: CONSTANTS.LOADING_CALL.updateBooking,
                    }))];
            case 1:
                _c.sent();
                requestBody = JSON.parse(JSON.stringify(action.payload));
                console.log("update booking request body", requestBody);
                _c.label = 2;
            case 2:
                _c.trys.push([2, 9, , 12]);
                return [4 /*yield*/, (0, effects_1.call)(function () {
                        return FUNCTIONS.callApiUrl({
                            url: apiUrl,
                            headers: {
                                "Content-type": "application/json",
                            },
                            data: __assign(__assign({}, requestBody), { booking: bookingReferenceNumber, token: token }),
                            method: "POST",
                        });
                    })];
            case 3:
                result = _c.sent();
                if (!(((_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.status) === "SUCCESS")) return [3 /*break*/, 5];
                return [4 /*yield*/, (0, effects_1.delay)(CONSTANTS._loadBlockerDelay)];
            case 4:
                _c.sent();
                //yield put(LoadingStatus({ loadingStatus: CONSTANTS.LOADING_STATUS.loaded }));
                window.location.href = "".concat($settingsManagedText.SuccessUrl, "?booking=").concat(bookingReferenceNumber, "&token=").concat(token, "&edit=true");
                return [3 /*break*/, 8];
            case 5: return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.FailureMessage)((_b = result === null || result === void 0 ? void 0 : result.data) === null || _b === void 0 ? void 0 : _b.messages[0]))];
            case 6:
                _c.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({
                        loadingStatus: CONSTANTS.LOADING_STATUS.error,
                    }))];
            case 7:
                _c.sent();
                _c.label = 8;
            case 8: return [3 /*break*/, 12];
            case 9:
                error_2 = _c.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.FailureMessage)(error_2.message))];
            case 10:
                _c.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({
                        loadingStatus: CONSTANTS.LOADING_STATUS.error,
                    }))];
            case 11:
                _c.sent();
                return [3 /*break*/, 12];
            case 12: return [2 /*return*/];
        }
    });
}
exports.UpdateBookingSaga = UpdateBookingSaga;
function CancelBookingSaga(action) {
    var apiUrl, requestBody, result, error_3;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                apiUrl = "".concat(process.env.ENV_API_BOOKING_CANCEL_BOOKING);
                requestBody = {
                    booking: bookingReferenceNumber,
                    token: token,
                    bookingStatus: "Cancelled",
                };
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({
                        loadingStatus: CONSTANTS.LOADING_STATUS.loading,
                        loadingCall: CONSTANTS.LOADING_CALL.cancelBooking,
                    }))];
            case 1:
                _b.sent();
                _b.label = 2;
            case 2:
                _b.trys.push([2, 10, , 13]);
                return [4 /*yield*/, (0, effects_1.call)(function () {
                        return FUNCTIONS.callApiUrl({
                            url: apiUrl,
                            method: "POST",
                            headers: {
                                "Content-type": "application/json",
                            },
                            data: requestBody,
                        });
                    })];
            case 3:
                result = _b.sent();
                if (!(((_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.status) === "SUCCESS")) return [3 /*break*/, 6];
                return [4 /*yield*/, (0, effects_1.delay)(CONSTANTS._loadBlockerDelay)];
            case 4:
                _b.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({
                        loadingStatus: CONSTANTS.LOADING_STATUS.loaded,
                    }))];
            case 5:
                _b.sent();
                return [3 /*break*/, 9];
            case 6: return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.FailureMessage)(CONSTANTS.GENERAL_ERROR_MESSAGE))];
            case 7:
                _b.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({
                        loadingStatus: CONSTANTS.LOADING_STATUS.error,
                    }))];
            case 8:
                _b.sent();
                _b.label = 9;
            case 9:
                window.location.reload();
                return [3 /*break*/, 13];
            case 10:
                error_3 = _b.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.FailureMessage)(error_3.message))];
            case 11:
                _b.sent();
                return [4 /*yield*/, (0, effects_1.put)((0, globalSlice_1.LoadingStatus)({
                        loadingStatus: CONSTANTS.LOADING_STATUS.error,
                    }))];
            case 12:
                _b.sent();
                return [3 /*break*/, 13];
            case 13: return [2 /*return*/];
        }
    });
}
exports.CancelBookingSaga = CancelBookingSaga;
//ALL SAGAS
function bookingSagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, (0, effects_1.takeLatest)(ActionTypes_1.SagaActions.GET_BOOKING_SAGA, GetBookingSaga)];
            case 1:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.takeLatest)(ActionTypes_1.SagaActions.UPDATE_BOOKING_SAGA, UpdateBookingSaga)];
            case 2:
                _a.sent();
                return [4 /*yield*/, (0, effects_1.takeLatest)(ActionTypes_1.SagaActions.CANCEL_BOOKING_SAGA, CancelBookingSaga)];
            case 3:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
exports.default = bookingSagas;
