"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateSubTitlePriceSummary = exports.validateStepDependency = exports.getIdFromBookingStepName = void 0;
var types_1 = require("../racwa/types");
var steps_root_1 = __importStar(require("./steps-root"));
var getIdFromBookingStepName = function (stepName) {
    var stepId = 0;
    Object.values(steps_root_1.default).forEach(function (item) {
        if (stepName === item.id) {
            stepId = item.id;
        }
    });
    return stepId;
};
exports.getIdFromBookingStepName = getIdFromBookingStepName;
function validateStepDependency(parentId, state) {
    return steps_root_1.StepDependencies[parentId](state);
}
exports.validateStepDependency = validateStepDependency;
//TODO: Should this function be refactored and moved into RACWA?
function updateSubTitlePriceSummary(data, isMembershipValid, bookingTypeId) {
    var _a, _b, _c, _d;
    var priceSummary = "";
    var price = "";
    //RACWA
    //ENSURE GetBatteries API is adjusted accordingly for priceVariants: {...} and memberPrice & basePrice being null
    if (data === null || data === void 0 ? void 0 : data.priceVariants) {
        if (bookingTypeId === types_1.EBookingTypeId.deliveryInstall) {
            price = isMembershipValid === "YES" ? (_a = data === null || data === void 0 ? void 0 : data.priceVariants) === null || _a === void 0 ? void 0 : _a.diMemberPrice : (_b = data === null || data === void 0 ? void 0 : data.priceVariants) === null || _b === void 0 ? void 0 : _b.diBasePrice;
        }
        if (bookingTypeId === types_1.EBookingTypeId.deliveryOnly) {
            price = isMembershipValid === "YES" ? (_c = data === null || data === void 0 ? void 0 : data.priceVariants) === null || _c === void 0 ? void 0 : _c.doMemberPrice : (_d = data === null || data === void 0 ? void 0 : data.priceVariants) === null || _d === void 0 ? void 0 : _d.doBasePrice;
        }
        //ALL OTHER BRANDS
        //ENSURE GetBatteries API is adjusted accordingly for priceVariants: null and memberPrice & basePrice filled
    }
    else {
        price = isMembershipValid === "YES" ? data === null || data === void 0 ? void 0 : data.memberPrice : data === null || data === void 0 ? void 0 : data.basePrice;
    }
    //All expect AANT
    priceSummary = "".concat(isMembershipValid === "YES" ? 'Member price' : 'Non-member price', ": $").concat(price === undefined || price === null || Number.isNaN(price) ? 'N/A' : price);
    //RAA: always use memeber price
    if (process.env.ENV_BRAND === "raa") {
        price = data === null || data === void 0 ? void 0 : data.memberPrice;
        priceSummary = "Member price : $".concat(price === undefined || price === null || Number.isNaN(price) ? 'N/A' : price);
    }
    return priceSummary;
}
exports.updateSubTitlePriceSummary = updateSubTitlePriceSummary;
