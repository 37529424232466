"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleReset = exports.ClearVehicleSelections = exports.VehicleContinue = exports.VehicleVariantSelected = exports.VehicleEditBookingSuccess = exports.VehicleVariants = exports.VehicleYearGroupingSelected = exports.VehicleYearGroupings = exports.VehicleModelSelected = exports.VehicleModelsSearch = exports.VehicleModelsFetchStart = exports.VehicleMakeSelected = exports.VehicleMakesSearch = exports.VehicleRegoSearch = exports.VehicleRegoState = exports.VehicleMakesFetchStart = exports.selectVehicleRegRequired = exports.selectVehicleWorkshopFitment = exports.selectVehicleHasBattery = exports.selectVehicleOEMFitment = exports.selectVehicleRegoNumber = exports.selectVehicleRegoSearch = exports.selectVehicleRegoNVIC = exports.selectVehicleRegoState = exports.selectVehicleSummary = exports.selectVehicleApiStatus = exports.selectVehicleLabel = exports.selectVehicleId = exports.selectVehicleVariant = exports.selectVehicleVariants = exports.selectVehicleYearGrouping = exports.selectVehicleYearGroupings = exports.selectVehicleModel = exports.selectVehicleModelsSearch = exports.selectVehicleModelsStart = exports.selectVehicleMake = exports.selectVehicleMakesSearch = exports.selectVehicleMakesStart = exports.vehicleSlice = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var constants_1 = require("@/utils/constants");
//Client Managed Text
var $vehicleManagedText = constants_1.WINDOW_MESSAGES === null || constants_1.WINDOW_MESSAGES === void 0 ? void 0 : constants_1.WINDOW_MESSAGES.Vehicle;
var initialState = {
    vehicleMakesFetchStart: [],
    vehicleApiStatus: "",
    vehicleOEMFitment: "",
    vehicleHasBattery: "",
    vehicleWorkshopFitment: "",
    vehicleRegRequired: "",
    vehicleRegoSearch: [],
    vehicleRegoNVIC: "",
    vehicleMakesSearch: [],
    vehicleMakeSelected: "",
    vehicleModelsFetchStart: [],
    vehicleModelsSearch: [],
    vehicleModelSelected: "",
    vehicleYearGroupings: [],
    vehicleYearGroupingSelected: "",
    vehicleVariants: [],
    vehicleVariantSelected: "",
    vehicleId: "",
    vehicleRegoNumber: "",
    vehicleSummary: "",
    vehicleRegoState: "",
};
exports.vehicleSlice = (0, toolkit_1.createSlice)({
    name: "vehicle",
    initialState: initialState,
    reducers: {
        VehicleMakesFetchStart: function (state, action) {
            return __assign(__assign({}, state), { vehicleMakesFetchStart: action.payload });
        },
        VehicleRegoState: function (state, action) {
            return __assign(__assign({}, state), { vehicleRegoState: action.payload });
        },
        VehicleRegoSearch: function (state, action) {
            var _a = action.payload || {}, _b = _a.data, data = _b === void 0 ? {} : _b, _c = _a.regoNumber, regoNumber = _c === void 0 ? "" : _c;
            var _d = data.vehicleSummary, vehicleSummary = _d === void 0 ? {} : _d, _e = data.vehicleId, vehicleId = _e === void 0 ? "" : _e, _f = data.nvic, nvic = _f === void 0 ? "" : _f, _g = data.status, status = _g === void 0 ? "" : _g;
            return __assign(__assign({}, state), { vehicleApiStatus: status, vehicleRegoSearch: action.payload ? action.payload : state.vehicleRegoSearch, vehicleRegoNVIC: nvic, vehicleId: vehicleId !== undefined ? vehicleId : "", vehicleRegoNumber: regoNumber.toUpperCase(), vehicleSummary: "".concat(vehicleSummary && vehicleSummary.make ? vehicleSummary.make : "").concat(vehicleSummary && vehicleSummary.model ? ", " + vehicleSummary.model : "").concat(vehicleSummary && vehicleSummary.year ? ", " + vehicleSummary.year : "").concat(vehicleSummary && vehicleSummary.variants ? ", " + vehicleSummary.variants : ""), vehicleMakeSelected: vehicleSummary && vehicleSummary.make, vehicleModelSelected: vehicleSummary && vehicleSummary.model, vehicleYearGroupingSelected: vehicleSummary && vehicleSummary.year, vehicleVariantSelected: vehicleSummary && vehicleSummary.variants });
        },
        VehicleMakesSearch: function (state, action) {
            return __assign(__assign({}, state), { vehicleMakesSearch: action.payload ? action.payload : state.vehicleMakesSearch });
        },
        VehicleMakeSelected: function (state, action) {
            return __assign(__assign({}, state), { vehicleModelsSearch: [], vehicleModelSelected: "", vehicleYearGroupings: [], vehicleYearGroupingSelected: "", vehicleVariants: [], vehicleVariantSelected: "", vehicleId: "", vehicleOEMFitment: "", vehicleHasBattery: "", vehicleWorkshopFitment: "", vehicleMakeSelected: action.payload ? action.payload : state.vehicleMakeSelected });
        },
        VehicleModelsFetchStart: function (state, action) {
            return __assign(__assign({}, state), { vehicleModelsFetchStart: action.payload });
        },
        VehicleModelsSearch: function (state, action) {
            return __assign(__assign({}, state), { vehicleModelsSearch: action.payload ? action.payload : state.vehicleModelsSearch });
        },
        VehicleModelSelected: function (state, action) {
            return __assign(__assign({}, state), { vehicleModelSelected: action.payload ? action.payload : state.vehicleModelSelected, vehicleYearGroupingSelected: "", vehicleVariants: [], vehicleVariantSelected: "", vehicleId: "", vehicleOEMFitment: "", vehicleHasBattery: "", vehicleWorkshopFitment: "" });
        },
        VehicleYearGroupings: function (state, action) {
            return __assign(__assign({}, state), { vehicleYearGroupings: action.payload ? action.payload : state.vehicleYearGroupings });
        },
        VehicleYearGroupingSelected: function (state, action) {
            return __assign(__assign({}, state), { vehicleVariants: [], vehicleVariantSelected: "", vehicleId: "", vehicleOEMFitment: "", vehicleHasBattery: "", vehicleWorkshopFitment: "", vehicleYearGroupingSelected: action.payload ? action.payload : state.vehicleYearGroupingSelected });
        },
        VehicleVariants: function (state, action) {
            var transformedVariants = action.payload.length ? action.payload.map(function (item) { return item.split("|"); }) : action.payload;
            return __assign(__assign({}, state), { vehicleVariants: transformedVariants ? transformedVariants : state.vehicleVariants });
        },
        VehicleVariantSelected: function (state, action) {
            var _a = action.payload ? action.payload : "", vehicleId = _a.vehicleId, vehicleVariantSelected = _a.vehicleVariantSelected;
            return __assign(__assign({}, state), { vehicleOEMFitment: "", vehicleHasBattery: "", vehicleWorkshopFitment: "", vehicleId: vehicleId !== undefined ? vehicleId : "", vehicleVariantSelected: vehicleVariantSelected !== undefined ? vehicleVariantSelected : "" });
        },
        VehicleContinue: function (state, action) {
            var _a = action.payload, oemFitment = _a.oemFitment, hasBatteryForVehicle = _a.hasBatteryForVehicle, workshopFitment = _a.workshopFitment, registrationRequired = _a.registrationRequired;
            return __assign(__assign({}, state), { vehicleOEMFitment: oemFitment ? oemFitment : state.vehicleOEMFitment, vehicleHasBattery: hasBatteryForVehicle ? hasBatteryForVehicle : state.vehicleHasBattery, vehicleWorkshopFitment: workshopFitment, vehicleRegRequired: registrationRequired, vehicleSummary: "".concat(state.vehicleMakeSelected ? state.vehicleMakeSelected : "").concat(state.vehicleModelSelected ? ", " + state.vehicleModelSelected : "").concat(state.vehicleYearGroupingSelected ? ", " + state.vehicleYearGroupingSelected : "").concat(state.vehicleVariantSelected ? ", " + state.vehicleVariantSelected : "") });
        },
        VehicleEditBookingSuccess: function (state, action) {
            var _a = action.payload, make = _a.make, model = _a.model, yearGrouping = _a.yearGrouping, variant = _a.variant, vehicleId = _a.vehicleId;
            return __assign(__assign({}, state), { vehicleMakeSelected: make, vehicleModelSelected: model, vehicleYearGroupingSelected: yearGrouping, vehicleVariantSelected: variant, vehicleOEMFitment: "NO", vehicleHasBattery: "YES", vehicleWorkshopFitment: "NO", vehicleId: vehicleId });
        },
        ClearVehicleSelections: function (state) {
            return __assign(__assign({}, initialState), { vehicleMakesFetchStart: state.vehicleMakesFetchStart });
        },
        VehicleReset: function (state) {
            return __assign(__assign({}, initialState), { vehicleMakesFetchStart: state.vehicleMakesFetchStart });
        },
    },
});
//Selectors
var selectVehicleMakesStart = function (state) { return state.vehicle.vehicleMakesFetchStart; };
exports.selectVehicleMakesStart = selectVehicleMakesStart;
var selectVehicleMakesSearch = function (state) { return state.vehicle.vehicleMakesSearch; };
exports.selectVehicleMakesSearch = selectVehicleMakesSearch;
var selectVehicleMake = function (state) { return state.vehicle.vehicleMakeSelected; };
exports.selectVehicleMake = selectVehicleMake;
var selectVehicleModelsStart = function (state) { return state.vehicle.vehicleModelsFetchStart; };
exports.selectVehicleModelsStart = selectVehicleModelsStart;
var selectVehicleModelsSearch = function (state) { return state.vehicle.vehicleModelsSearch; };
exports.selectVehicleModelsSearch = selectVehicleModelsSearch;
var selectVehicleModel = function (state) { return state.vehicle.vehicleModelSelected; };
exports.selectVehicleModel = selectVehicleModel;
var selectVehicleYearGroupings = function (state) { return state.vehicle.vehicleYearGroupings; };
exports.selectVehicleYearGroupings = selectVehicleYearGroupings;
var selectVehicleYearGrouping = function (state) { return state.vehicle.vehicleYearGroupingSelected; };
exports.selectVehicleYearGrouping = selectVehicleYearGrouping;
var selectVehicleVariants = function (state) { return state.vehicle.vehicleVariants; };
exports.selectVehicleVariants = selectVehicleVariants;
var selectVehicleVariant = function (state) { return state.vehicle.vehicleVariantSelected; };
exports.selectVehicleVariant = selectVehicleVariant;
var selectVehicleId = function (state) { return state.vehicle.vehicleId; };
exports.selectVehicleId = selectVehicleId;
var selectVehicleLabel = function (state) { return state.vehicle.vehicleVariantSelected; };
exports.selectVehicleLabel = selectVehicleLabel;
var selectVehicleApiStatus = function (state) { return state.vehicle.vehicleApiStatus; };
exports.selectVehicleApiStatus = selectVehicleApiStatus;
var selectVehicleSummary = function (state) { return state.vehicle.vehicleSummary; };
exports.selectVehicleSummary = selectVehicleSummary;
var selectVehicleRegoState = function (state) { return state.vehicle.vehicleRegoState; };
exports.selectVehicleRegoState = selectVehicleRegoState;
var selectVehicleRegoNVIC = function (state) { return state.vehicle.vehicleRegoNVIC; };
exports.selectVehicleRegoNVIC = selectVehicleRegoNVIC;
var selectVehicleRegoSearch = function (state) { return state.vehicle.vehicleRegoSearch; };
exports.selectVehicleRegoSearch = selectVehicleRegoSearch;
var selectVehicleRegoNumber = function (state) { return state.vehicle.vehicleRegoNumber; };
exports.selectVehicleRegoNumber = selectVehicleRegoNumber;
var selectVehicleOEMFitment = function (state) { return state.vehicle.vehicleOEMFitment; };
exports.selectVehicleOEMFitment = selectVehicleOEMFitment;
var selectVehicleHasBattery = function (state) { return state.vehicle.vehicleHasBattery; };
exports.selectVehicleHasBattery = selectVehicleHasBattery;
var selectVehicleWorkshopFitment = function (state) { return state.vehicle.vehicleWorkshopFitment; };
exports.selectVehicleWorkshopFitment = selectVehicleWorkshopFitment;
var selectVehicleRegRequired = function (state) { return state.vehicle.vehicleRegRequired; };
exports.selectVehicleRegRequired = selectVehicleRegRequired;
//Actions
exports.VehicleMakesFetchStart = (_a = exports.vehicleSlice.actions, _a.VehicleMakesFetchStart), exports.VehicleRegoState = _a.VehicleRegoState, exports.VehicleRegoSearch = _a.VehicleRegoSearch, exports.VehicleMakesSearch = _a.VehicleMakesSearch, exports.VehicleMakeSelected = _a.VehicleMakeSelected, exports.VehicleModelsFetchStart = _a.VehicleModelsFetchStart, exports.VehicleModelsSearch = _a.VehicleModelsSearch, exports.VehicleModelSelected = _a.VehicleModelSelected, exports.VehicleYearGroupings = _a.VehicleYearGroupings, exports.VehicleYearGroupingSelected = _a.VehicleYearGroupingSelected, exports.VehicleVariants = _a.VehicleVariants, exports.VehicleEditBookingSuccess = _a.VehicleEditBookingSuccess, exports.VehicleVariantSelected = _a.VehicleVariantSelected, exports.VehicleContinue = _a.VehicleContinue, exports.ClearVehicleSelections = _a.ClearVehicleSelections, exports.VehicleReset = _a.VehicleReset;
exports.default = exports.vehicleSlice.reducer;
