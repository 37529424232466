"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrandConfig = void 0;
var types_1 = require("./types");
var config_1 = require("../brands/nrma/config");
var config_2 = require("../brands/racv/config");
var config_3 = require("../brands/ract/config");
var config_4 = require("../brands/racq/config");
var config_5 = require("../brands/racwa/config");
var config_6 = require("../brands/aant/config");
var config_7 = require("../brands/raa/config");
var brandConfigMap = {
    nrma: config_1.config,
    racv: config_2.config,
    ract: config_3.config,
    racq: config_4.config,
    racwa: config_5.config,
    aant: config_6.config,
    raa: config_7.config,
};
exports.BrandConfig = brandConfigMap[types_1.BrandKey];
