"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.config = void 0;
var constants_1 = require("@/utils/constants");
// NRMA Brand Config
exports.config = {
    Location: __assign({}, constants_1.WINDOW_MESSAGES.Location),
    Vehicle: __assign(__assign({}, constants_1.WINDOW_MESSAGES.Vehicle), { RegoCancelButton: {
            label: "Cancel",
            showRegoCancelButton: true,
        }, RegoConfirmButton: {
            label: "Confirm vehicle",
            tertiary: false,
            icon: "tick",
        } }),
    Battery: __assign(__assign({}, constants_1.WINDOW_MESSAGES.Battery), { SelectBatteryButton: {
            label: "Select Battery",
            showSelectBatteryButton: true,
            tertiary: false,
            isMemberValidAndEligibleOnClick: null,
        }, BatteryIcons: [
            {
                label: "Delivery",
                icon: "nrma-delivery",
            },
            {
                label: "Installation",
                icon: "nrma-wrench",
            },
            {
                label: "Battery Recycling",
                icon: "nrma-recycle",
            },
        ], Confirmation: {
            showAllPrices: false,
            memberPriceLabel: "Your Member price",
            nonMemberPriceLabel: "Non-member price",
        } }),
    Appointment: __assign(__assign({}, constants_1.WINDOW_MESSAGES.Appointment), { AdditionalNotesLabel: "Help us locate your car", PurchaserToggleButtonsType: "checkbox", SubmitButton: {
            label: "Confirm booking",
            tertiary: true,
        } }),
    EditBooking: {
        Heading: "Edit booking",
    },
    Settings: __assign({}, constants_1.WINDOW_MESSAGES.Settings),
    Icons: {
        IconsMultiColor: false,
        InfoCircle: "info-circle",
        Alert: "nrma-warning-circle-outline",
        Warning: "nrma-warning-circle-outline",
        Close: "nrma-close",
        CloseCircle: "close-circle",
        Battery: "nrma-battery",
        Vehicle: "nrma-vehicle",
        EditOutline: "edit-outline",
        Calendar: "nrma-calendar",
        BookingSuccess: "nrma-calendar-tick",
    },
};
